import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Subscription.css";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Dropdown, Media, Button } from "react-bootstrap";
import { t, withTranslation } from "react-multi-lang";

const SubscriptionView = (props) => {
    useEffect(() => { }, []);
    return (
        <>
            <div className="main-wrapper subscription-view">
                <Container>
                    <h3 className="heading-title">Subscription View</h3>
                    <div className="subscription-list-my-profile">
                        <Table className="my-profile-table">
                            <thead>
                                <tr>
                                    <th>{t("title")}</th>
                                    <th>{t("duration")}</th>
                                    <th>{t("price")}</th>
                                    <th>{t("no_of_subscribers")}</th>
                                    <th>{t("total_earning")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Beno Darry</td>
                                    <td>10 Mins</td>
                                    <td>$15</td>
                                    <td>2</td>
                                    <td>$10</td>
                                </tr>
                                <tr>
                                    <td>Jerif Daniel</td>
                                    <td>20 Mins</td>
                                    <td>$15</td>
                                    <td>5</td>
                                    <td>$40</td>
                                </tr>
                                <tr>
                                    <td>Suderson</td>
                                    <td>15 Mins</td>
                                    <td>$15</td>
                                    <td>10</td>
                                    <td>$100</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <Row>
                        <Col md={12}>
                            <div className="subscription-list-card">
                                <Table className="subscription-list-table">
                                    <thead>
                                        <tr>
                                            <th>{t("title")}</th>
                                            <th>{t("duration")}</th>
                                            <th>{t("price")}</th>
                                            <th>{t("no_of_subscribers")}</th>
                                            <th>{t("total_earning")}</th>
                                            <th>{t("booking_list")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Beno Darry</td>
                                            <td>10 Mins</td>
                                            <td>$1</td>
                                            <td>12 Subcribers</td>
                                            <td>$10</td>
                                            <td>
                                                <Dropdown className="subscription-list-dropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <ul className="list-unstyled subscription-list-dropdown-item">
                                                            <Media as="li">
                                                                <Link to="#">{t("view")}</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">{t("edits")}</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">{t("delete")}</Link>
                                                            </Media>
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Beno Darry</td>
                                            <td>10 Mins</td>
                                            <td>$1</td>
                                            <td>12 Subcribers</td>
                                            <td>$10</td>
                                            <td>
                                                <Dropdown className="subscription-list-dropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <ul className="list-unstyled subscription-list-dropdown-item">
                                                            <Media as="li">
                                                                <Link to="#">{t("view")}</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">{t("edits")}</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">{t("delete")}</Link>
                                                            </Media>
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Beno Darry</td>
                                            <td>10 Mins</td>
                                            <td>$1</td>
                                            <td>12 Subcribers</td>
                                            <td>$10</td>
                                            <td>
                                                <Dropdown className="subscription-list-dropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <ul className="list-unstyled subscription-list-dropdown-item">
                                                            <Media as="li">
                                                                <Link to="#">{t("view")}</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">{t("edits")}</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">{t("delete")}</Link>
                                                            </Media>
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Row>
                                    <Col md={12}>
                                        <div className="btn-view-more-sec">
                                            <Button className="btn-view-more">{t("view_more")}<i class="fas fa-chevron-right ml-2"></i></Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(SubscriptionView));
