import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";

import "./FilterView.css";
import { tr } from "date-fns/locale";

class SingleFilterView extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      reviewModal: false,
    };
  }

  showReviewModal = () => {
    this.setState({ reviewModal: true });
  };

  closeReviewModal = () => {
    this.setState({ reviewModal: false });
  };

  toggle = () =>
    this.setState((currentState) => ({ show: !currentState.show }));

  render() {
    return (
      <>
        <div className="single-filter-view-sec">
          <div className="single-filter-view-header-sec">
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item>Data</Breadcrumb.Item>
            </Breadcrumb>
            <div className="btn-action-sec">
              <Button className="btn-join">{t("join_fan_club")}</Button>
              <Button className="btn-copy">
                <Image
                  src="assets/images/copy.svg"
                  alt="user-image"
                  className="icons-img"
                />
              </Button>
            </div>
          </div>
          <div className="single-filter-view-body-sec">
            <div className="single-filter-profile-sec">
              <div className="profile-wrapper">
                <div className="profile-img-sec">
                  <Image
                    src={
                      window.location.origin + "/assets/images/profile-img.jpg"
                    }
                    alt="user-image"
                    className="filter-profile-img"
                  />
                </div>
              </div>
              <div className="profile-details-sec">
                <h2 className="title">{t("emily_kuroda")}</h2>
                <p className="sub-desc">{t("actress_gilmore_girls")}</p>
                <div className="review-title-sec">
                  <i className="fas fa-star"></i>
                  <span className="star-rating-count">5</span>
                  <span className="review-count">(20)</span>
                  <Button
                    className="btn-see-all-review"
                    onClick={this.showReviewModal}
                  >
                    {t("see_all_review")}
                  </Button>
                </div>
                <h4 className="desc">
                 {t("review_para")}
                </h4>
              </div>
            </div>
            <div className="single-filter-review-sec">
              <div className="rating-left-sec">
                <ul className="list-unstyled rating-sec">
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <span className="rating-count">5.0</span>
                </ul>
                <Link to="#" className="see-all-link">
                  {t("see_all")} 323 {t("reviews")}
                </Link>
              </div>
              <div className="review-info-sec">
                <h4 className="title">
                 {t("reviewed_by")} K***** {t("on_january")} 4th, 2021
                </h4>
                <p className="desc">
                  <span id="display_1">
                    {t("review_disp1")}
                  </span>
                  {this.state.show && (
                    <span id="display_1">
                     {t("review_disp2")}
                    </span>
                  )}
                  <Link to="#" className="more-link" onClick={this.toggle}>
                    {" "}
                    {t("more")}
                  </Link>
                </p>
              </div>
            </div>
            <div className="request-btn-sec">
              <Link to="/request-booking">
                <Button className="btn-request">{t("request")} $75</Button>
              </Link>
              <Link to="/text-me">
                <Button className="btn-chat-action">{t("chat")} $75</Button>
              </Link>
              <Link to="#">
                <Button className="btn-how-it-works">
                  <Image
                    src="assets/images/info-icon.svg"
                    alt="user-image"
                    className="info-icon"
                  />{" "}
                 {t("how_doesit_work")}
                </Button>
              </Link>
            </div>
            <div className="video-post-sec">
              <Row>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-1.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>{t("rh")}</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">{t("peptalk")}</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-1.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>{t("rh")}</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">{t("peptalk")}</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-1.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>{t("rh")}</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">{t("peptalk")}</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="space-mg"></div>
              <Row>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-1.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>{t("rh")}</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">{t("peptalk")}</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-1.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>{t("rh")}</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">{t("peptalk")}</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="video-post-item before-overlay">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-1.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-last-join-sec">
                      <h5 className="title">{t("want_more")}</h5>
                      <p className="desc">
                      {t("video-last-join-title")}
                      </p>
                      <Link to="#">
                        <Button className="btn-join-secondary">
                          {t("join_fans_club")}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="single-filter-review-sec">
              <div className="rating-left-sec">
                <p className="see-all-link">{t("typically_responds_in")}</p>
                <h5 className="desc">
                  <Image
                    src={window.location.origin + "/assets/images/zap.svg"}
                    alt="user-image"
                    className="zap-icon"
                  />
                  20 {t("hours")}
                </h5>
              </div>
              <div className="review-info-sec">
                <div className="tag-wrapper-sec">
                  <span className="tag-info">
                    <Link to="#">#{t("actors")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("cw")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("gilmore_girls")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("tv")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#24 {t("hour_delivery")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("binge_watching_netflix")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("childhood_favorites")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("asian_american")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#1{t("day_delivery")}</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="single-filter-footer-sec">
              <Row>
                <Col md={12} lg={6} xl={6}>
                  <div className="single-filter-about-sec">
                    <h4 className="title">{t("what_is_model")}</h4>
                    <Row>
                      <Col md={4} className="resp-mrg-btm-xs">
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/mobile-icon.svg"
                              }
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">{t("send_your_request")}</h4>
                        </div>
                      </Col>
                      <Col md={4} className="resp-mrg-btm-xs">
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/video-icon.svg"
                              }
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">{t("get_your_video")}</h4>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/thumsup.svg"
                              }
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">{t("make_their_year")}</h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12} lg={6} xl={6}>
                  <div className="single-filter-about-sec">
                    <h4 className="title-1">
                     {t("what_does_a_good_request_look_like")}
                    </h4>
                    <h5 className="sub-title">{t("tip_1")}</h5>
                    <p className="desc">{t("tip_1_content")}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.reviewModal}
          handleClose={this.closeReviewModal}
          className="review-modal"
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>{t("latest_reviews")}</h4>
            </Modal.Title>
            <i
              className="fas fa-times close-icon"
              onClick={this.closeReviewModal}
            ></i>
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <div className="review-modal-view-sec">
              <div className="rating-left-sec">
                <ul className="list-unstyled rating-sec">
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <span className="rating-count">5 {t("stars")}</span>
                </ul>
              </div>
              <div className="review-info-sec">
                <h4 className="title">
                 {t("reviewed_by")} K***** {t("on_january")} 4th, 2021
                </h4>
                <p className="desc">"{t("awesome")}!!"</p>
                <p className="sub-desc">{t("private_model_video")}</p>
              </div>
            </div>
            <div className="review-modal-view-sec">
              <div className="rating-left-sec">
                <ul className="list-unstyled rating-sec">
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <span className="rating-count">5 {t("stars")}</span>
                </ul>
              </div>
              <div className="review-info-sec">
                <h4 className="title">
                 {t("reviewed_by")} K***** {t("on_january")}4th, 2021
                </h4>
                <p className="desc">"{t("awesome")}!!"</p>
                <p className="sub-desc">{t("private_model_video")}</p>
              </div>
            </div>
            <div className="review-modal-view-sec">
              <div className="rating-left-sec">
                <ul className="list-unstyled rating-sec">
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <li>
                    <i class="fas fa-star"></i>
                  </li>
                  <span className="rating-count">5 {t("stars")}</span>
                </ul>
              </div>
              <div className="review-info-sec">
                <h4 className="title">
                  {t("reviewed_by")} K***** {t("on_january")} 4th, 2021
                </h4>
                <p className="desc">"{t("awesome")}!!"</p>
                <p className="sub-desc">{t("private_model_video")}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SingleFilterView));
