import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "./Auth.css";
import { forgotPasswordStart } from "../../store/actions/UserAction";
import { t, withTranslation } from "react-multi-lang";

const $ = window.$;

const ForgotPasswordIndex = (props) => {
  const [forgotPasswordInputData, setForgotPasswordInputData] = useState({});

  const forgotpwdSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("invalid_email_note"))
      .required(t("email_is_required")),
  });

  const handleForgotPassword = (values) => {
    // event.preventDefault();
    props.dispatch(forgotPasswordStart(values));
  };
  useEffect(() => {}, []);
  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="auth-warpper">
            <Row>
              <Col
                md={{ span: 6, offset: 3 }}
                sm={6}
                lg={{ span: 6, offset: 3 }}
                xl={{ span: 6, offset: 3 }}
              >
                <div className="login-box">
                  <h2>{t("forgot_your_password")}</h2>

                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={forgotpwdSchema}
                    onSubmit={(values) => handleForgotPassword(values)}
                  >
                    {({
                      touched,
                      errors,
                      values,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form className="login-form" noValidate>
                        <label className="form-label">{t("email")}</label>
                        <Field
                          type="text"
                          placeholder={t("email")}
                          autoFocus
                          name="email"
                          className="form-control"
                          value={values.email}
                        />

                        <ErrorMessage
                          component={"div"}
                          name="email"
                          className="text-danger text-right"
                        />

                        <Button
                          className="btn-email mt-4"
                          type="submit"
                          // disabled={props.forgotPassword.buttonDisable}
                          // disabled={!forgotPasswordInputData.email}
                          disabled={!values.email}
                        >
                          {props.forgotPassword.loadingButtonContent !== null
                            ? props.forgotPassword.loadingButtonContent
                            : t("forgot_password")}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                  {/* <Form
                    className="login-form mt-4"
                    onSubmit={handleForgotPassword}
                    method="post"
                  >
                    <Form.Group controlId="formBasicEmail" className="mb-4">
                      <Form.Label>{t("email")}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t("you@email.com")}
                        value={forgotPasswordInputData.email}
                        name="email"
                        onChange={(event) =>
                          setForgotPasswordInputData({
                            ...forgotPasswordInputData,
                            email: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                    {/* <Button
                      className="btn-reset"
                      onClick={handleForgotPassword}
                      className="btn btn-auth btn-lg"
                      disabled={props.forgotPassword.buttonDisable}
                    >
                      {props.forgotPassword.loadingButtonContent !== null
                        ? props.forgotPassword.loadingButtonContent
                        : "Forgot Password"}
                    </Button> */}

                  {/* <Button
                      className="btn-email"
                      onClick={handleForgotPassword}
                      // disabled={props.forgotPassword.buttonDisable}
                      disabled={!forgotPasswordInputData.email}
                    >
                      {props.forgotPassword.loadingButtonContent !== null
                        ? props.forgotPassword.loadingButtonContent
                        : t("forgot_password")}
                    </Button>
                  </Form> */}
                  <div className="login-footer-sub-sec-1">
                    <span className="login-desc-text-1">
                      <Link to="/login">{t("already_have_an_account")}</Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ForgotPasswordIndex));
