import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Media,
} from "react-bootstrap";
import { connect } from "react-redux";

const AllUpCommingEventsCard = (props) => {

  return (
    <>
      {/* <div className="all-upcoming-events-header-sec">
                <h4>All Upcoming events</h4>
            </div> */}
      <div className="all-upcoming-events-box">
        <Link to="/event-request">
          <div className="all-upcoming-events-card">
            <div className="all-upcoming-events-img-sec">
              <Image
                src={
                  window.location.origin + "/assets/images/live-events/live-events-1.jpg"
                }
                alt="live-events-img"
                className="all-upcoming-events-img"
              />
              <div className="all-upcoming-ticket-price-card">
                <div className="my-ticket-count">
                  <Image
                    src="assets/images/live-tickets-icon.svg"
                    alt="user-image"
                    className="my-ticket-icon"
                  /> <span>4</span>
                </div>
                <div className="my-price-amount-count">
                  $0.00
                </div>
              </div>
            </div>
            <div className="all-upcoming-events-info">
              <div className="all-upcoming-events-status">
                Event Upcoming
              </div>
              <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
            </div>
          </div>
        </Link>
        <Link to="/event-request">
          <div className="all-upcoming-events-card">
            <div className="all-upcoming-events-img-sec">
              <Image
                src={
                  window.location.origin + "/assets/images/live-events/live-events-2.jpg"
                }
                alt="live-events-img"
                className="all-upcoming-events-img"
              />
              <div className="all-upcoming-ticket-price-card">
                <div className="my-ticket-count">
                  <Image
                    src="assets/images/live-tickets-icon.svg"
                    alt="user-image"
                    className="my-ticket-icon"
                  /> <span>4</span>
                </div>
                <div className="my-price-amount-count">
                  $0.00
                </div>
              </div>
            </div>
            <div className="all-upcoming-events-info">
              <div className="all-upcoming-events-status">
                Event Upcoming
              </div>
              <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
            </div>
          </div>
        </Link>
        <Link to="/event-request">
          <div className="all-upcoming-events-card">
            <div className="all-upcoming-events-img-sec">
              <Image
                src={
                  window.location.origin + "/assets/images/live-events/live-events-3.jpg"
                }
                alt="live-events-img"
                className="all-upcoming-events-img"
              />
              <div className="all-upcoming-ticket-price-card">
                <div className="my-ticket-count">
                  <Image
                    src="assets/images/live-tickets-icon.svg"
                    alt="user-image"
                    className="my-ticket-icon"
                  /> <span>4</span>
                </div>
                <div className="my-price-amount-count">
                  $0.00
                </div>
              </div>
            </div>
            <div className="all-upcoming-events-info">
              <div className="all-upcoming-events-status">
                Event Upcoming
              </div>
              <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
            </div>
          </div>
        </Link>
        <Link to="/event-request">
          <div className="all-upcoming-events-card">
            <div className="all-upcoming-events-img-sec">
              <Image
                src={
                  window.location.origin + "/assets/images/live-events/live-events-4.jpg"
                }
                alt="live-events-img"
                className="all-upcoming-events-img"
              />
              <div className="all-upcoming-ticket-price-card">
                <div className="my-ticket-count">
                  <Image
                    src="assets/images/live-tickets-icon.svg"
                    alt="user-image"
                    className="my-ticket-icon"
                  /> <span>4</span>
                </div>
                <div className="my-price-amount-count">
                  $0.00
                </div>
              </div>
            </div>
            <div className="all-upcoming-events-info">
              <div className="all-upcoming-events-status">
                Event Upcoming
              </div>
              <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(AllUpCommingEventsCard);
