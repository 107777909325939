import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";
import "./BillingAccount.css";
import { addBankAccountStart } from "../../../store/actions/BankAccountAction";
import Skeleton from "react-loading-skeleton";

const NewAddBillingAccount = (props) => {

  const addbillingSchema = Yup.object().shape({
    route_number: Yup.string()
      .required(t("route_number_is_required")),
    account_number: Yup.string()
      .required(t("account_number_is_required")),
    first_name: Yup.string()
      .required(t("first_name_is_required")),
    last_name: Yup.string()
      .required(t("last_name_is_required")),
  });

  const handleSubmit = (values) => {
    props.dispatch(addBankAccountStart(values));
  };

  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="add-bank-card">
            <h3 className="title">{t("add_billing_account")}</h3>
              <Formik
                initialValues={{
                  route_number: "",
                  account_number: "",
                  first_name: "",
                  last_name: "",
                  bank_type: "savings",
                  business_name: "",
                }}
                validationSchema={addbillingSchema}
                onSubmit={(values) => handleSubmit(values)}>

                {({ touched, errors, isSubmitting, setFieldValue }) => (
                  <Form
                    className="add-bank-form"
                    noValidate
                  >
                    <Row>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("route_number")}</label>
                        <Field
                          type="text"
                          placeholder={t("route_number_placeholder")}
                          name="route_number"
                          className="form-control"
                        />
                        <ErrorMessage component={"div"} name="route_number" className='text-danger text-right' />
                      </Col>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("account_number")}</label>
                        <Field
                          type="text"
                          placeholder={t("account_number_placeholder")}
                          name="account_number"
                          className="form-control"
                        />
                        <ErrorMessage component={"div"} name="account_number" className='text-danger text-right' />
                      </Col>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("first_name")} *</label>
                        <Field
                          type="text"
                          placeholder={t("first_name_placeholder")}
                          name="first_name"
                          className="form-control"
                        />
                        <ErrorMessage component={"div"} name="first_name" className='text-danger text-right' />
                      </Col>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("last_name")} *</label>
                        <Field
                          type="text"
                          placeholder={t("last_name_placeholder")}
                          name="last_name"
                          className="form-control"
                        />
                        <ErrorMessage component={"div"} name="last_name" className='text-danger text-right' />
                      </Col>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("bank_type")} *</label>
                        <Field
                          as="select"
                          placeholder={t("bank_type_placeholder")}
                          name="bank_type"
                          className="form-control"
                        >
                          <option value="savings">{t("savings")}</option>
                          <option value="checking">{t("checking")}</option>
                        </Field>
                        <ErrorMessage component={"div"} name="bank_type" className='text-danger text-right' />
                      </Col>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("business_name")}</label>
                        <Field
                          type="text"
                          placeholder={t("business_name_placeholder")}
                          name="business_name"
                          className="form-control"
                        />
                        <ErrorMessage component={"div"} name="business_name" className='text-danger text-right' />
                      </Col>
                      <Col>
                        <div className="btn-continue-sec">
                          <Button
                            className="btn-continue"
                            type="submit"
                            disabled={props.bankAccount.buttonDisable}
                          >
                            {props.bankAccount.loadingButtonContent !== null
                              ? props.bankAccount.loadingButtonContent
                              : t("submit")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
          </div>
        </Container>
      </div >
    </>
  )

}

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.addBankAccountInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(NewAddBillingAccount));