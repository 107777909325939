import React from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const ModelVideoSec = (props) => {
  return (
    <Col md={4} className="resp-mrg-btm-xs">
      <Link to={`/post/` + props.post.post_unique_id}>
        <div className="video-post-item">
          <div className="video-post-img-sec">
            <Image
              src={
                props.post.file_type == "video"
                  ? props.post.preview_image
                  : props.post.file
              }
              alt="user-image"
              className="video-post-img"
            />
          </div>
          <div className="video-post-bottom-sec">
            <div className="video-user-img-sec">
              <div className="video-user-top-flex">
                <div className="avatar-img">
                  <Image
                    src={props.post.model.picture}
                    alt="user-image"
                    className="avatar-img"
                  />
                </div>
                <div className="avatar-img-1">
                  <Image
                    src={props.post.user.picture}
                    alt="user-image"
                    className="avatar-img"
                  />
                </div>
              </div>
              {props.post.file_type == "video" ? (
                <div className="play-icon-sec">
                  <i class="fas fa-play"></i>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="video-tag-sec">
              <Badge className="badge-tag">
                #{props.post.bookings.occasion_name}
              </Badge>
            </div>
          </div>
        </div>
      </Link>
    </Col>
  );
};

export default ModelVideoSec;
