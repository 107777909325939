import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AgoraRTC from "agora-rtc-sdk-ng";
import { Form, Button, Container, Row, Col, Table, Media, Image } from "react-bootstrap";
import {
  endVideocallStart,
  joinVideocallStart,
} from "../../store/actions/VideoCall";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";

const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
const $ = window.$;

const AgoraOnetoOne = (props) => {

  var rtc = {
    // For the local client.
    client: null,
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null,
  };


  var localTracks = {
    videoTrack: null,
    audioTrack: null
  };

  var localTrackState = {
    videoTrackEnabled: true,
    audioTrackEnabled: true
  }

  var options = {
    // Pass your app ID here.
    appId: configuration.get("configData.agora_app_id"),
    // Set the channel name.
    channel: props.videoCallData.virtual_id,
    // Pass a token if your project enables the App Certificate.
    token: props.videoCallData.agora_token ? props.videoCallData.agora_token : null,
    uid: null,
  };

  var remoteUsers = {};

  async function startBasicCall() {

    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    rtc.client.on("user-published", handleUserPublished);
    rtc.client.on("user-unpublished", handleUserUnpublished);

    [options.uid, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
      // join the channel
      await rtc.client.join(options.appId, options.channel, options.token, null),
      // create local tracks, using microphone and camera
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack(),
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack()
    ]);
    $("#local-player").empty();

    localTracks.videoTrack.play("local-player");

    await rtc.client.publish(Object.values(localTracks));

    // Publish the local audio and video tracks to the channel.
    // await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);

    console.log("publish success!");

    props.dispatch(
      joinVideocallStart({
        video_call_id: props.videoCallData.video_call_id,
        isOwner: props.isOwner,
      })
    );
  }

  async function subscribe(user, mediaType) {
    const uid = user.uid;
    // subscribe to a remote user
    await rtc.client.subscribe(user, mediaType);
    console.log("subscribe success");
    if (mediaType === 'video') {

      const remoteVideoTrack = user.videoTrack;
      $("#remote-playerlist").empty();
      $("#remote-playerlist").show();
      $("#remote-player-placeholder").hide();
      remoteVideoTrack.play("remote-playerlist");
    }
    if (mediaType === 'audio') {
      user.audioTrack.play();
    }
  }

  function handleUserPublished(user, mediaType) {
    const id = user.uid;
    remoteUsers[id] = user;
    subscribe(user, mediaType);
    if (mediaType === "video") {
      $("#remote-playerlist").show();
      $("#remote-player-placeholder").hide();
    }
  }

  function handleUserUnpublished(user, mediaType) {
    if (mediaType === "video") {
      $("#remote-playerlist").hide();
      $("#remote-player-placeholder").show();
    }
  }

  startBasicCall();

  async function leaveCall(is_owner) {

    if (is_owner) {
      // Destroy the local audio and video tracks.
      rtc.localAudioTrack.close();
      rtc.localVideoTrack.close();

      // Traverse all remote users.
      rtc.client.remoteUsers.forEach(user => {
        // Destroy the dynamically created DIV container.
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });
      props.dispatch(
        endVideocallStart({
          video_call_id: props.videoCallData.video_call_id,
        })
      );
      window.location.assign("/incoming-video-call");
    } else {
      // Leave the channel.
      await rtc.client.leave();
      window.location.assign("/my-tickets");
    }


  }

  async function backAction(is_owner) {

    if (is_owner) {
      // Destroy the local audio and video tracks.
      rtc.localAudioTrack.close();
      rtc.localVideoTrack.close();

      // Traverse all remote users.
      rtc.client.remoteUsers.forEach(user => {
        // Destroy the dynamically created DIV container.
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });
      window.location.assign("/incoming-video-call");
    } else {
      // Leave the channel.
      await rtc.client.leave();
      window.location.assign("/my-tickets");
    }


  }

  async function muteAudio() {
    if (!rtc.localAudioTrack) return;
    if (localTrackState.audioTrackEnabled == true) {
      await rtc.localAudioTrack.setEnabled(false);
      localTrackState.audioTrackEnabled = false;
      $("#mute-audio").hide();
      $("#unmute-audio").show();
    } else {
      await rtc.localAudioTrack.setEnabled(true);
      localTrackState.audioTrackEnabled = true;
      $("#mute-audio").show();
      $("#unmute-audio").hide();
    }
  }

  async function muteVideo() {
    if (!rtc.localVideoTrack) return;
    if (localTrackState.videoTrackEnabled == true) {
      await rtc.localVideoTrack.setEnabled(false);
      localTrackState.videoTrackEnabled = false;
      $("#mute-video").hide();
      $("#unmute-video").show();
      $("#local-player").hide();
      $("#local-player-placeholder").show();
    } else {
      await rtc.localVideoTrack.setEnabled(true);
      localTrackState.videoTrackEnabled = true;
      $("#mute-video").show();
      $("#unmute-video").hide();
      $("#local-player").show();
      $("#local-player-placeholder").hide();
    }
  }

  return (
    <>
      <div className="live-streaming-card">
        <div id="remote-playerlist" className="live-streaming-bg-img" />
        <Image
          src={props.videoCallData ? props.videoCallData.is_model ?
            props.videoCallData.user_picture :
            props.videoCallData.model_picture :
            window.location.origin + "/assets/images/live-streaming/live-bg.png"
          }
          alt="live-streaming-img"
          className="live-streaming-bg-img"
          id="remote-player-placeholder"
          style={{ display: "none" }}
        />
        <div className="live-streaming-user-img-sec">
          <div id="local-player" className="live-streaming-user-img" />
          <Image
            src={
              props.videoCallData ? props.videoCallData.is_model ?
                props.videoCallData.model_picture :
                props.videoCallData.user_picture :
                window.location.origin + "/assets/images/live-streaming/live-user.png"
            }
            alt="live-streaming-img"
            className="live-streaming-user-img"
            id="local-player-placeholder"
            style={{ display: "none" }}
          />
        </div>
        <div className="live-streaming-action-btn-sec">
          <ul className="list-unstyled live-streaming-action-btn">
            {props.isOwner ? (
              <Media as="li"
                onClick={() =>
                  muteVideo()
                }
              >
                <Link>
                  <Image
                    src={
                      window.location.origin + "/assets/images/live-streaming/camera.svg"
                    }
                    alt="action-icons"
                    className="action-icon-img"
                    id="mute-video"
                  />
                  <Image
                    src={
                      window.location.origin + "/assets/images/live-streaming/camera-off.svg"
                    }
                    alt="action-icons"
                    className="action-icon-img"
                    id="unmute-video"
                    style={{ display: "none" }}
                  />
                </Link>
              </Media>
            ) : null}
            <Media as="li"
              className="call-animation"
              onClick={() => {
                if (window.confirm(t("leave_call_confirmation"))) {
                  leaveCall(props.isOwner)
                };
              }
              }
            >
              <Link>
                <Image
                  src={
                    window.location.origin + "/assets/images/live-streaming/end-call.svg"
                  }
                  alt="action-icons"
                  className="action-icon-img"
                />
              </Link>
            </Media>
            {props.isOwner ? (
              <Media as="li"
                onClick={() =>
                  muteAudio()
                }
              >
                <Link>
                  <Image
                    src={
                      window.location.origin + "/assets/images/live-streaming/mic.svg"
                    }
                    alt="action-icons"
                    className="action-icon-img"
                    id="mute-audio"
                  />
                  <Image
                    src={
                      window.location.origin + "/assets/images/live-streaming/mic-off.svg"
                    }
                    alt="action-icons"
                    className="action-icon-img"
                    id="unmute-audio"
                    style={{ display: "none" }}
                  />
                </Link>
              </Media>
            ) : null}
          </ul>
        </div>
        <div className="back-btn-sec">
          <Link to="#" onClick={() => backAction(props.isOwner)}>
            <Image
              src={
                window.location.origin + "/assets/images/live-streaming/back-icon.svg"
              }
              alt="action-icons"
              className="back-btn-img"
            />
          </Link>
        </div>
      </div>
    </>
  );
};


const mapStateToPros = (state) => ({

});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(AgoraOnetoOne));