import React, { Component } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Home.css";
import HomeFeatured from "./HomeFeatured";
import ModelSuggestionSec from "./ModelSuggestionSec";
import { t, withTranslation } from "react-multi-lang";

class SingleActorView extends Component {
  state = {
    show: false,
    howitworksModal: false,
  };

  toggle = () =>
    this.setState((currentState) => ({ show: !currentState.show }));

  showHowItWorksModal = () => {
    this.setState({ howitworksModal: true });
  };

  closeHowItWorksModal = () => {
    this.setState({ howitworksModal: false });
  };

  render() {
    return (
      <>
        <div className="single-filter-view-sec">
          <div className="single-filter-view-header-sec">
            <Breadcrumb>
              <Breadcrumb.Item href="#">{t("home")}</Breadcrumb.Item>
              <Breadcrumb.Item>{t("musician")}</Breadcrumb.Item>
              <Breadcrumb.Item active>{t("lead_singers")}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="btn-action-sec">
              <Button className="btn-join">{t("join_fan_club")}</Button>
              <Button className="btn-copy">
                <Image
                  src={window.location.origin + "/assets/images/copy.svg"}
                  alt="user-image"
                  className="icons-img"
                />
              </Button>
            </div>
          </div>
          <div className="single-filter-view-body-sec">
            <div className="single-filter-profile-sec">
              <div className="profile-wrapper">
                <div className="profile-img-sec">
                  <Image
                    src={window.location.origin + "/assets/images/img-2.jpg"}
                    alt="user-image"
                    className="filter-profile-img"
                  />
                </div>
              </div>
              <div className="profile-details-sec">
                <h2 className="title">{t("nick_lachey")}</h2>
                <p className="sub-desc">{t("singer_gegrees_tv_hos")}</p>
                <h4 className="desc">
                  {t("proceeds_from_these_models_will_go_to_feeding_america")}
                </h4>
                <Badge className="feeding-badge">
                  <Image
                    src={window.location.origin + "/assets/images/featured.svg"}
                    alt="user-image"
                    className="flag-icon-img"
                  />
                  {t("feeding_america")}
                </Badge>
              </div>
            </div>
            <div className="single-filter-review-sec">
              <div className="rating-left-sec">
                <ul className="list-unstyled rating-sec">
                  <Media as="li">
                    <i class="fas fa-star"></i>
                  </Media>
                  <Media as="li">
                    <i class="fas fa-star"></i>
                  </Media>
                  <Media as="li">
                    <i class="fas fa-star"></i>
                  </Media>
                  <Media as="li">
                    <i class="fas fa-star"></i>
                  </Media>
                  <Media as="li">
                    <i class="fas fa-star"></i>
                  </Media>
                  <span className="rating-count">5.0</span>
                </ul>
                <Link to="#" className="see-all-link">
                  {t("see_all")} 323 {t("reviews")}
                </Link>
              </div>
              <div className="review-info-sec">
                <h4 className="title">{t("review-info_title")}</h4>
                <p className="desc">
                  <span id="display_1">{t("review-info_desc_first")}</span>
                  {this.state.show && (
                    <span id="display_1">{t("review-info_desc_second")}</span>
                  )}
                  <Link to="#" className="more-link" onClick={this.toggle}>
                    {" "}
                    {t("more")}
                  </Link>
                </p>
              </div>
            </div>
            <div className="request-btn-sec">
              <Link to="/request-booking">
                <Button className="btn-request">{t("request")} $75</Button>
              </Link>
              <Link to="/text-me">
                <Button className="btn-chat">{t("chat")} $75</Button>
              </Link>
              <Link to="#">
                <Button
                  className="btn-how-it-works"
                  onClick={this.showHowItWorksModal}
                >
                  <Image
                    src={
                      window.location.origin + "/assets/images/info-icon.svg"
                    }
                    alt="user-image"
                    className="info-icon"
                  />
                  {t("how_doesit_work")}
                </Button>
              </Link>
            </div>
            <div className="video-post-sec">
              <Row>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-2.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>{t("rh")}</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">{t("peptalk")}</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-3.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>{t("rh")}</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">{t("peptalk")}</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-4.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>{t("rh")}</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">{t("peptalk")}</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="space-mg"></div>
              <Row>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-5.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>{t("rh")}</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">{t("peptalk")}</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-6.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>{t("rh")}</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">{t("peptalk")}</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="video-post-item before-overlay">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-5.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-last-join-sec">
                      <h5 className="title">{t("want_more")}</h5>
                      <p className="desc">{t("video-last-join-title")}</p>
                      <Link to="#">
                        <Button className="btn-join-secondary">
                          {t("join_fans_club")}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="single-filter-review-sec">
              <div className="rating-left-sec">
                <p className="see-all-link">{t("typically_responds_in")}</p>
                <h5 className="desc">
                  <Image
                    src={window.location.origin + "/assets/images/zap.svg"}
                    alt="user-image"
                    className="zap-icon"
                  />
                  5 days
                </h5>
              </div>
              <div className="review-info-sec">
                <div className="tag-wrapper-sec">
                  <span className="tag-info">
                    <Link to="#">#{t("featured")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("lead_singers")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("more")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("musicians")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("reality_tv")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("singer_songwriter")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("tv_hosts")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("for_charity")}</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#{t("new_and_noteworthy")}</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="single-filter-footer-sec">
              <Row>
                <Col md={12} lg={6} xl={6}>
                  <div className="single-filter-about-sec">
                    <h4 className="title">
                      {t("what_is")} {configuration.get("configData.site_name")}
                      ?
                    </h4>
                    <Row>
                      <Col md={4} className="resp-mrg-btm-xs">
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/mobile-icon.svg"
                              }
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">{t("send_your_request")}</h4>
                        </div>
                      </Col>
                      <Col md={4} className="resp-mrg-btm-xs">
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/video-icon.svg"
                              }
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">{t("get_your_video")}</h4>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/thumsup.svg"
                              }
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">{t("make_their_year")}</h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12} lg={6} xl={6}>
                  <div className="single-filter-about-sec">
                    <h4 className="title-1">
                      {t("what_does_a_good_request_look_like")}
                    </h4>
                    <h5 className="sub-title">{t("tip_1")}</h5>
                    <p className="desc">{t("tip_1_content")}</p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="single-filter-footer-sec">
              <ModelSuggestionSec />
            </div>
          </div>
        </div>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.howitworksModal}
          handleClose={this.closeHowItWorksModal}
          className="review-modal time-line-modal"
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>{t("what_happens_when_i_request_a_model")}</h4>
            </Modal.Title>
            <i
              className="fas fa-times close-icon"
              onClick={this.closeHowItWorksModal}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <div className="howitswork-wrapper">
              <div className="howitswork-row">
                <div className="howitswork-icon">
                  <Image
                    src={window.location.origin + "/assets/images/calendar.svg"}
                    alt="user-image"
                    className="time-line-img"
                  />
                </div>
                <p className="time-line-desc">
                  {t("your_request_will_be_completed_within_days")}
                </p>
              </div>
              <div className="howitswork-row">
                <div className="howitswork-icon">
                  <Image
                    src={window.location.origin + "/assets/images/document.svg"}
                    alt="user-image"
                    className="time-line-img"
                  />
                </div>
                <p className="time-line-desc">
                  {t("your_receipt_information")}
                </p>
              </div>
              <div className="howitswork-row">
                <div className="howitswork-icon">
                  <Image
                    src={
                      window.location.origin + "/assets/images/envelop-pink.svg"
                    }
                    alt="user-image"
                    className="time-line-img"
                  />
                </div>
                <p className="time-line-desc">{t("when_your_request")}</p>
              </div>
              <div className="howitswork-row">
                <div className="howitswork-icon no-after">
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/credit-card-pink.svg"
                    }
                    alt="user-image"
                    className="time-line-img"
                  />
                </div>
                <p className="time-line-desc">{t("removed_reason")}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SingleActorView));
