import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Image,
	Media,
} from "react-bootstrap";
import { connect } from "react-redux";
import { fetchVideoCallViewStart } from "../../store/actions/VideoCall";
import AgoraOnetoOne from "../VideoCall/AgoraOnetoOne";
import { t, withTranslation } from "react-multi-lang";
import { useHistory } from "react-router";

const LiveStreamingIndex = (props) => {
	const history = useHistory();

	const params = useParams();

	useEffect(() => {
		props.dispatch(
			fetchVideoCallViewStart({
				video_call_unique_id: params.live_video_unique_id,
			})
		);
	}, []);

	const [skipRender, setSkipRender] = useState(true);

	useEffect(() => {
		if (!skipRender) {
			if (!props.videoCallDetails.loading && props.videoCallDetails.error !== false) {
				if (props.videoCallDetails.error.data.model_id == localStorage.getItem("userId")) {
					history.push("/incoming-video-call");
				} else {
					history.push("/my-tickets");
				}
			}
		}
		setSkipRender(false);
	}, [props.videoCallDetails]);

	return (
		<>
			{props.videoCallDetails.loading ? (
				t("loading")
			) : props.videoCallDetails.data.video_call ? (
				<AgoraOnetoOne
					videoCallData={props.videoCallDetails.data.video_call}
					isOwner={props.videoCallDetails.data.video_call.model_id == localStorage.getItem("userId") ? 1 : 0}
				/>
			) : null}

		</>
	);
};

const mapStateToPros = (state) => ({
	videoCallDetails: state.videocall.videoCallDetails,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(LiveStreamingIndex));
