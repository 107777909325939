import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { followUserStart,unFollowUserStart } from "../../store/actions/FollowAction";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { t, withTranslation } from "react-multi-lang";

const UserCardSec = (props) => {
  const joinFanClub = () => {
    props.dispatch(followUserStart({ user_id: props.user.user_id }));
  };

  const exitFanClub = () => {
    props.dispatch(unFollowUserStart({ user_id: props.user.user_id }));
  };

  const exitFanConfirmation = () => {
    confirmAlert({
      message: t('do_you_want_to_unfollow'),
      buttons: [
        {
          label: t('yes'),
          onClick: () => exitFanClub(props.user.show_unfollow === 1 ? exitFanClub :joinFanClub)
        },
        {
          label: t('no'),
        }
      ]
    });
  };



  return (
    <Col md={6} xl={3} lg={3}>
      {/* <Link to={`/${props.user.username}`}> */}
      <div className="feature-item">
        <div className="featured-card">
        <Link to={`/${props.user.username}`} className="title">
          <div className="overlay-slider"></div>
          <Image
            src={props.user.picture}
            alt={props.user.name}
            className="featured-img"
          />
           </Link>
          <Button className="subscribe-btn" onClick={exitFanConfirmation}>
            {props.user.show_unfollow === 1 ? t("exit_fanclub") : t("join_fanclub")}
          </Button>
          <Button className="unsubscribe-btn" style={{ display: "none" }}>
           {t("unsubscribe")}
          </Button>
          {props.user.is_featured ? (
            <span className="flag-icon-img-sec">
              <Image
                src={window.location.origin + "/assets/images/featured.svg"}
                alt="user-image"
                className="flag-icon-img"
              />
            </span>
          ) : null}
        </div>
        <div className="featured-details-sec">
          <div className="featured-details">
            <Link to={`/${props.user.username}`} className="title">
              {props.user.name}
            </Link>
          </div>
          <div className="featured-details">
            <Link to={`/${props.user.username}`} className="desc">
              {props.user.category_name} - {props.user.subcategory_name}
            </Link>
          </div>
        </div>
      </div>
      {/* </Link> */}
    </Col>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(withTranslation(UserCardSec));
