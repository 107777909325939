import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";
import "./BillingAccount.css";
import { addBankAccountStart } from "../../../store/actions/BankAccountAction";
import NewAddBillingAccount from "./NewAddBillingAccount";
import Skeleton from "react-loading-skeleton";

const AddBillingAccount = (props) => {
  const [inputData, setInputData] = useState({});


  const addbillingSchema = Yup.object().shape({
    nickname: Yup.string()
      .required(t("nickname_is_required")),
    account_number: Yup.string()
      .required(t("account_number_is_required")),
    // ifsc_code:Yup.string()
    // .required("Account number is required *"),
    route_number: Yup.string()
      .required(t("route_number_is_required")),
    account_holder_name: Yup.string()
      .required(t("account_holder_name_is_required")),
    // bank_name:Yup.string()
    // .required("Bank name is required *"),
    // swift_code:Yup.string()
    // .required("Swift Code is required *"),
    // iban_number:Yup.string()
    // .required("Name is required *"),

  });

  const handleSubmit = (values) => {
    // console.log(values);
    // event.preventDefault();

    props.dispatch(addBankAccountStart(values));
  };
  return <NewAddBillingAccount />

  // return (
  //   <>
  //     <div className="main-wrapper">
  //       <Container>
  //         <div className="add-bank-card">
  //           <h3 className="title">{t("add_billing_account")}</h3>

  //           <Formik
  //             initialValues={{
  //               nickname: "",
  //               account_number: "",
  //               ifsc_code: "",
  //               route_number: "",
  //               account_holder_name: "",
  //               bank_name: "",
  //               swift_code: "",
  //               iban_number: "",
  //             }}
  //             validationSchema={addbillingSchema}
  //             onSubmit={(values) => handleSubmit(values)}>

  //             {({ touched, errors, isSubmitting, setFieldValue }) => (
  //               <Form
  //                 className="add-bank-form"
  //                 noValidate
  //               // method="POST"
  //               >
  //                 <Row>
  //                   <Col md={6}>
  //                     <label className="form-label">{t("nick_name")}</label>
  //                     <Field
  //                       type="text"
  //                       placeholder={t("nick_name")}
  //                       name="nickname"
  //                       className="form-control"

  //                     />

  //                     <ErrorMessage component={"div"} name="nickname" className='text-danger text-right' />
  //                     {/*                   
  //                 <Form.Group controlId="formBasicEmail">
  //                   <Form.Label>{t("nick_name")}</Form.Label>
  //                   <Form.Control
  //                     type="text"
  //                     placeholder="Nick Name"
  //                     value={inputData.nickname}
  //                     name="nickname"
  //                     onChange={(event) => {
  //                       setInputData({
  //                         ...inputData,
  //                         nickname: event.currentTarget.value,
  //                       });
  //                     }}
  //                   />
  //                 </Form.Group> */}

  //                     <label className="form-label">{t("account_number")}</label>
  //                     <Field
  //                       type="number"
  //                       placeholder={t("account_number")}
  //                       name="account_number"
  //                       className="form-control"

  //                     />

  //                     <ErrorMessage component={"div"} name="account_number" className='text-danger text-right' />


  //                     {/* <Form.Group controlId="formBasicEmail">
  //                   <Form.Label>{t("account_number")}</Form.Label>
  //                   <Form.Control
  //                     type="number"
  //                     placeholder="Account Number"
  //                     value={inputData.account_number}
  //                     name="account_number"
  //                     onChange={(event) => {
  //                       setInputData({
  //                         ...inputData,
  //                         account_number: event.currentTarget.value,
  //                       });
  //                     }}
  //                   />
  //                 </Form.Group> */}

  //                     <label className="form-label">{t("ifsc_code")}</label>
  //                     <Field
  //                       type="text"
  //                       placeholder={t("ifsc_codes")}
  //                       name="ifsc_code"
  //                       className="form-control"

  //                     />

  //                     <ErrorMessage component={"div"} name="ifsc_code" className='text-danger text-right' />

  //                     {/* <Form.Group controlId="formBasicEmail">
  //                   <Form.Label>{t("ifsc_code")}</Form.Label>
  //                   <Form.Control
  //                     type="text"
  //                     placeholder="IFSC Code"
  //                     value={inputData.ifsc_code}
  //                     name="ifsc_code"
  //                     onChange={(event) => {
  //                       setInputData({
  //                         ...inputData,
  //                         ifsc_code: event.currentTarget.value,
  //                       });
  //                     }}
  //                   />
  //                 </Form.Group> */}

  //                     <label className="form-label">{t("route_number")}</label>
  //                     <Field
  //                       type="text"
  //                       placeholder={t("route_number")}
  //                       name="route_number"
  //                       className="form-control"

  //                     />

  //                     <ErrorMessage component={"div"} name="route_number" className='text-danger text-right' />



  //                     {/* <Form.Group controlId="formBasicEmail">
  //                   <Form.Label>{t("route_number")}</Form.Label>
  //                   <Form.Control
  //                     type="text"
  //                     required
  //                     placeholder="Route Number"
  //                     value={inputData.route_number}
  //                     name="route_number"
  //                     onChange={(event) => {
  //                       setInputData({
  //                         ...inputData,
  //                         route_number: event.currentTarget.value,
  //                       });
  //                     }}
  //                   />
  //                 </Form.Group> */}

  //                   </Col>
  //                   <Col md={6}>

  //                     <label className="form-label">{t("account_holder_name")}</label>
  //                     <Field
  //                       type="text"
  //                       placeholder={t("account_holder_name")}
  //                       name="account_holder_name"
  //                       className="form-control"

  //                     />

  //                     <ErrorMessage component={"div"} name="account_holder_name" className='text-danger text-right' />

  //                     {/* <Form.Group controlId="formBasicEmail">
  //                   <Form.Label>{t("account_holder_name")}</Form.Label>
  //                   <Form.Control
  //                     type="text"
  //                     placeholder="Account Holder Name"
  //                     value={inputData.account_holder_name}
  //                     name="account_holder_name"
  //                     onChange={(event) => {
  //                       setInputData({
  //                         ...inputData,
  //                         account_holder_name: event.currentTarget.value,
  //                       });
  //                     }}
  //                   />
  //                 </Form.Group> */}

  //                     <label className="form-label">{t("bank_name")}</label>
  //                     <Field
  //                       type="text"
  //                       placeholder={t("account_holder_name")}
  //                       name="bank_name"
  //                       className="form-control"

  //                     />

  //                     <ErrorMessage component={"div"} name="bank_name" className='text-danger text-right' />

  //                     {/* <Form.Group controlId="formBasicEmail">
  //                   <Form.Label>{t("bank_name")}</Form.Label>
  //                   <Form.Control
  //                     type="text"
  //                     placeholder="Bank Name"
  //                     value={inputData.bank_name}
  //                     name="bank_name"
  //                     onChange={(event) => {
  //                       setInputData({
  //                         ...inputData,
  //                         bank_name: event.currentTarget.value,
  //                       });
  //                     }}
  //                   />
  //                 </Form.Group> */}

  //                     <label className="form-label">{t("swift_code")}</label>
  //                     <Field
  //                       type="text"
  //                       placeholder={t("swift_code")}
  //                       name="swift_code"
  //                       className="form-control"

  //                     />

  //                     <ErrorMessage component={"div"} name="swift_code" className='text-danger text-right' />

  //                     {/* <Form.Group controlId="formBasicEmail">
  //                   <Form.Label>{t("swift_code")}</Form.Label>
  //                   <Form.Control
  //                     type="text"
  //                     placeholder="Swift Code"
  //                     value={inputData.swift_code}
  //                     name="swift_code"
  //                     onChange={(event) => {
  //                       setInputData({
  //                         ...inputData,
  //                         swift_code: event.currentTarget.value,
  //                       });
  //                     }}
  //                   />
  //                 </Form.Group> */}


  //                     <label className="form-label">{t("iban_number")}</label>
  //                     <Field
  //                       type="text"
  //                       placeholder={t("iban_number")}
  //                       name="iban_number"
  //                       className="form-control"

  //                     />

  //                     <ErrorMessage component={"div"} name="iban_number" className='text-danger text-right' />

  //                     {/* <Form.Group controlId="formBasicEmail">
  //                   <Form.Label>{t("iban_number")}</Form.Label>
  //                   <Form.Control
  //                     type="text"
  //                     placeholder="IBAN Number"
  //                     value={inputData.iban_number}
  //                     name="iban_number"
  //                     pattern="[0-9]*"
  //                     onChange={(event) => {
  //                       setInputData({
  //                         ...inputData,
  //                         iban_number: event.currentTarget.value,
  //                       });
  //                     }}
  //                   />
  //                 </Form.Group> */}

  //                     <div className="btn-continue-sec">
  //                       <Button
  //                         className="btn-continue"
  //                         type="submit"
  //                         disabled={props.bankAccount.buttonDisable}
  //                       >
  //                         {props.bankAccount.loadingButtonContent !== null
  //                           ? props.bankAccount.loadingButtonContent
  //                           : t("submit")}
  //                       </Button>
  //                     </div>
  //                   </Col>
  //                 </Row>
  //               </Form>
  //             )}
  //           </Formik>
  //         </div>
  //       </Container>
  //     </div>
  //   </>
  // );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.addBankAccountInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(AddBillingAccount));
