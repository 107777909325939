import React from 'react';
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Image,
    Media,
} from "react-bootstrap";

const SingleUpcommingEventModelcard = (props) => {
    return (
        <Link to={`/event-request/${props.model.username}`}>
            <div className="all-upcoming-events-card">
                <div className="all-upcoming-events-img-sec">
                    <Image
                        src={props.model.picture}
                        alt="live-events-img"
                        className="all-upcoming-events-img"
                    />
                    {/* <div className="all-upcoming-ticket-price-card">
                        <div className="my-ticket-count">
                            <Image
                                src="assets/images/live-tickets-icon.svg"
                                alt="user-image"
                                className="my-ticket-icon"
                            /> <span>4</span>
                        </div>
                    </div> */}
                </div>
                <div className="all-upcoming-events-info">
                    <h5>{props.model.name}</h5>
                    <div className="all-upcoming-events-status">
                        {props.model.category_name}
                    </div>
                    <div className="my-price-amount-count">
                        {props.model.video_call_amount_formatted}
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default SingleUpcommingEventModelcard;