import {
  ADD_BILLING_ACCOUNT_START,
  ADD_BILLING_ACCOUNT_SUCCESS,
  ADD_BILLING_ACCOUNT_FAILURE,
  DELETE_BILLING_ACCOUNT_START,
  DELETE_BILLING_ACCOUNT_SUCCESS,
  DELETE_BILLING_ACCOUNT_FAILURE,
  GET_BILLING_ACCOUNT_START,
  GET_BILLING_ACCOUNT_SUCCESS,
  GET_BILLING_ACCOUNT_FAILURE,
  MAKE_DEFAULT_BILLING_ACCOUNT_START,
  MAKE_DEFAULT_BILLING_ACCOUNT_SUCCESS,
  MAKE_DEFAULT_BILLING_ACCOUNT_FAILURE,
  FETCH_SINGLE_BILLING_ACCOUNT_START,
  FETCH_SINGLE_BILLING_ACCOUNT_SUCCESS,
  FETCH_SINGLE_BILLING_ACCOUNT_FAILURE,
  ADD_BILLING_ACCOUNT_DATA,
} from "./ActionConstant";

// Add billing account actions.

export function getBillingAccountData(name, value) {
  return {
    type: ADD_BILLING_ACCOUNT_DATA,
    name,
    value,
  };
}

export function addBillingAccountStart(data) {
  return {
    type: ADD_BILLING_ACCOUNT_START,
    data,
  };
}

export function addBillingAccountSuccess(data) {
  return {
    type: ADD_BILLING_ACCOUNT_SUCCESS,
    data,
  };
}

export function addBillingAccountFailure(error) {
  return {
    type: ADD_BILLING_ACCOUNT_FAILURE,
    error,
  };
}

// Delete bank account actions.

export function deleteBillingAccountStart(data) {
  return {
    type: DELETE_BILLING_ACCOUNT_START,
    data,
  };
}

export function deleteBillingAccountSuccess(data) {
  return {
    type: DELETE_BILLING_ACCOUNT_SUCCESS,
    data,
  };
}

export function deleteBillingAccountFailure(error) {
  return {
    type: DELETE_BILLING_ACCOUNT_FAILURE,
    error,
  };
}

// Get billing account actions.

export function getBillingAccountStart(data) {
  return {
    type: GET_BILLING_ACCOUNT_START,
    data,
  };
}

export function getBillingAccountSuccess(data) {
  return {
    type: GET_BILLING_ACCOUNT_SUCCESS,
    data,
  };
}

export function getBillingAccountFailure(error) {
  return {
    type: GET_BILLING_ACCOUNT_FAILURE,
    error,
  };
}

// Get billing account actions.

export function fetchSingleBillingAccountStart(data) {
  return {
    type: FETCH_SINGLE_BILLING_ACCOUNT_START,
    data,
  };
}

export function fetchSingleBillingAccountSuccess(data) {
  return {
    type: FETCH_SINGLE_BILLING_ACCOUNT_SUCCESS,
    data,
  };
}

export function fetchSingleBillingAccountFailure(error) {
  return {
    type: FETCH_SINGLE_BILLING_ACCOUNT_FAILURE,
    error,
  };
}

// Get billing account actions.

export function makeDefaultBillingAccountStart(data) {
  return {
    type: MAKE_DEFAULT_BILLING_ACCOUNT_START,
    data,
  };
}

export function makeDefaultBillingAccountSuccess(data) {
  return {
    type: MAKE_DEFAULT_BILLING_ACCOUNT_SUCCESS,
    data,
  };
}

export function makeDefaultBillingAccountFailure(error) {
  return {
    type: MAKE_DEFAULT_BILLING_ACCOUNT_FAILURE,
    error,
  };
}
