import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TableLoader = () => {

    return (
        <>
            <Skeleton
                count={6}
                height={55}
            />
        </>
    );
}

export default TableLoader;