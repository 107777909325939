import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Image,
    Media,
} from "react-bootstrap";
import { connect } from "react-redux";
import { t, withTranslation } from "react-multi-lang";

const AllPastEventsCard = (props) => {


    return (
        <>
            <div className="all-past-events-header-sec">
                <h4>{t("all_past_events")}</h4>
            </div>
            <div className="all-past-events-box">
                <div className="all-past-events-card">
                    <div className="all-past-events-img-sec">
                        <Image
                           src={
                            window.location.origin + "/assets/images/live-events/live-events-1.jpg"
                          }
                            alt="live-events-img"
                            className="all-past-events-img"
                        />
                    </div>
                    <div className="all-past-events-info">
                        <div className="all-past-events-status">
                           {t("event_ended")}
                        </div>
                        <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        <div className="all-past-events-footer">
                            <p>Missed out on this event?</p>
                            <Link to="#">
                                Get notified
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-card">
                    <div className="all-past-events-img-sec">
                        <Image
                            src={
                                window.location.origin + "/assets/images/live-events/live-events-2.jpg"
                              }
                            alt="live-events-img"
                            className="all-past-events-img"
                        />
                    </div>
                    <div className="all-past-events-info">
                        <div className="all-past-events-status">
                            Event Ended
                        </div>
                        <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        <div className="all-past-events-footer">
                            <p>Missed out on this event?</p>
                            <Link to="#">
                                Get notified
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-card">
                    <div className="all-past-events-img-sec">
                        <Image
                             src={
                                window.location.origin + "/assets/images/live-events/live-events-3.jpg"
                              }
                            alt="live-events-img"
                            className="all-past-events-img"
                        />
                    </div>
                    <div className="all-past-events-info">
                        <div className="all-past-events-status">
                            Event Ended
                        </div>
                        <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        <div className="all-past-events-footer">
                            <p>Missed out on this event?</p>
                            <Link to="#">
                                Get notified
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-card">
                    <div className="all-past-events-img-sec">
                        <Image
                             src={
                                window.location.origin + "/assets/images/live-events/live-events-4.jpg"
                              }
                            alt="live-events-img"
                            className="all-past-events-img"
                        />
                    </div>
                    <div className="all-past-events-info">
                        <div className="all-past-events-status">
                            Event Ended
                        </div>
                        <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        <div className="all-past-events-footer">
                            <p>Missed out on this event?</p>
                            <Link to="#">
                                Get notified
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-card">
                    <div className="all-past-events-img-sec">
                        <Image
                             src={
                                window.location.origin + "/assets/images/live-events/live-events-5.jpg"
                              }
                            alt="live-events-img"
                            className="all-past-events-img"
                        />
                    </div>
                    <div className="all-past-events-info">
                        <div className="all-past-events-status">
                            Event Ended
                        </div>
                        <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        <div className="all-past-events-footer">
                            <p>Missed out on this event?</p>
                            <Link to="#">
                                Get notified
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-card">
                    <div className="all-past-events-img-sec">
                        <Image
                             src={
                                window.location.origin + "/assets/images/live-events/live-events-4.jpg"
                              }
                            alt="live-events-img"
                            className="all-past-events-img"
                        />
                    </div>
                    <div className="all-past-events-info">
                        <div className="all-past-events-status">
                            Event Ended
                        </div>
                        <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        <div className="all-past-events-footer">
                            <p>Missed out on this event?</p>
                            <Link to="#">
                                Get notified
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-card">
                    <div className="all-past-events-img-sec">
                        <Image
                             src={
                                window.location.origin + "/assets/images/live-events/live-events-3.jpg"
                              }
                            alt="live-events-img"
                            className="all-past-events-img"
                        />
                    </div>
                    <div className="all-past-events-info">
                        <div className="all-past-events-status">
                            Event Ended
                        </div>
                        <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        <div className="all-past-events-footer">
                            <p>Missed out on this event?</p>
                            <Link to="#">
                                Get notified
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-card">
                    <div className="all-past-events-img-sec">
                        <Image
                             src={
                                window.location.origin + "/assets/images/live-events/live-events-2.jpg"
                              }
                            alt="live-events-img"
                            className="all-past-events-img"
                        />
                    </div>
                    <div className="all-past-events-info">
                        <div className="all-past-events-status">
                            Event Ended
                        </div>
                        <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        <div className="all-past-events-footer">
                            <p>Missed out on this event?</p>
                            <Link to="#">
                                Get notified
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(null, mapDispatchToProps)(withTranslation(AllPastEventsCard));
