import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const UpCommingEventModelLoader = () => {
  return (
    <>
      {[...Array(8)].map(() =>
        <Skeleton width={360} height={288} />
      )}
    </>
  );
}

export default UpCommingEventModelLoader;