import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./TextMe.css";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Image,
} from "react-bootstrap";
import { t, withTranslation } from "react-multi-lang";

const TextMe = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="main-wrapper text-me-wrapper">
        <div className="text-me-sec-wrapper">
          <Container>
            <Row>
              <Col md={12}>
                <div className="text-me-card">
                  <Row>
                    <Col md={7} xl={6} className="resp-mrg-btm-xs">
                      <h1 className="title">
                        {t("want_to_get_the_full_site_experience")}
                        <br></br>
                        <span>{t("download_our_app")}</span>
                      </h1>
                      <p className="desc">{t("text_me_desc")}</p>
                      <Form>
                        <InputGroup className="">
                          <FormControl
                            placeholder="(XXX) XXX-XXXX"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">
                              {t("send")}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form>
                    </Col>
                    <Col md={5} xl={6}>
                      <div className="qr-code-sec">
                        <h6>{t("use_our_qrcode")}</h6>
                        <p className="desc">{t("qr_code_desc")}</p>
                        <canvas height="128" width="128"></canvas>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col md={6} xl={4} className="resp-mrg-btm-md">
                    <div className="text-me-card-sec">
                      <div className="text-me-item">
                        <div className="text-me-img-sec">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/message.svg"
                            }
                            alt="user-image"
                            className="text-me-icon"
                          />
                        </div>
                        <div className="text-me-info">
                          <h5>{t("chat_with_celebrities")}</h5>
                          <p>{t("chat_with_c_desc")}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xl={4} className="resp-mrg-btm-md">
                    <div className="text-me-card-sec">
                      <div className="text-me-item">
                        <div className="text-me-img-sec">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/phone.svg"
                            }
                            alt="user-image"
                            className="text-me-icon"
                          />
                        </div>
                        <div className="text-me-info">
                          <h5>{t("access_to_more_content")}</h5>
                          <p>{t("access_to_desc")} </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xl={4}>
                    <div className="text-me-card-sec">
                      <div className="text-me-item">
                        <div className="text-me-img-sec">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/gift-1.svg"
                            }
                            alt="user-image"
                            className="text-me-icon"
                          />
                        </div>
                        <div className="text-me-info">
                          <h5>{t("share_like_never_befores")}</h5>
                          <p>{t("share_like_desc")}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(TextMe));
