import React from "react";
import {Image, Row, Col } from "react-bootstrap";
import "./NotFound.css";

const NotFoundIndex = () => {
  return (
    <>
      <div className="not-found-sec">
        <Row>
          <Col md={12}>
            <div className="not-found-img-sec">
              <Image
                alt="not-found"
                src={window.location.origin + "/assets/images/404-img.svg"}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NotFoundIndex;
