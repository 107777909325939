import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Subscription.css";
import { Button, Image } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { subscriptionCreateStart } from "../../store/actions/SubscriptionAction";
import { getKycDocumentStart } from "../../store/actions/KycDocumentAction";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { t, withTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";


const CreateSubscription = (props) => {
  useEffect(() => {}, []);
  const [inputData, setInputData] = useState({});
  const handleSubmit = (values) => {
    // event.preventDefault();
    props.dispatch(subscriptionCreateStart(values));
  };

  useEffect(() => {
    props.dispatch(getKycDocumentStart());
    props.dispatch(fetchUserDetailsStart());
  }, []);

  const createSubSchema = Yup.object().shape({
    title: Yup.string()
      .required(t("title_is_required"))
      .matches(/^(?=.*[a-zA-Z])(?=.{6,})/, t("must_contain_6_characters")),

    plan: Yup.number()
      .min(1, t("the_duration_must_be_at_least_1"))
      .required(t("duration_is_required")),

    amount: Yup.number().required(t("price_is_required")),
  });

  return (
    <>
      {props.profile.data.category_name === "" ? (
        <>
          <h6 className="text-center text-white">{t("please_update_categories")}</h6>
          <Link to="/categories">
            <div className="col-md-12 text-center">
              <Button className="btn btn-success">
                {t("update_categories")}
              </Button>
            </div>
          </Link>
        </>
      ) : (
        <>
          {props.kycDocDetails.data.is_document_verified === 2 ? (
            <div className="main-wrapper">
              <div className="create-subscription-sec">
                <div className="create-subscription-card">
                  <h3 className="title">{t("create_subscription")}</h3>
                  <Formik
                    initialValues={{
                      title: "",
                      plan: "",
                      amount: "",
                    }}
                    validationSchema={createSubSchema}
                    onSubmit={(values) => handleSubmit(values)}
                  >
                    {({
                      touched,
                      errors,
                      values,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form className="create-subscription-form" noValidate>
                        <label className="form-label">{t("title")}*</label>
                        <Field
                          type="text"
                          placeholder={t("mathew_perry")}
                          value={values.title}
                          name="title"
                          autoFocus
                          className="form-control"
                        />

                        <ErrorMessage
                          component={"div"}
                          name="title"
                          className="text-danger text-right"
                        />

                        <label className="form-label">{t("duration")}*</label>
                        <Field
                          type="number"
                          placeholder={t("enter_the_Duration_in_mins")}
                          value={values.plan}
                          name="plan"
                          min="1"
                          step="any"
                          className="form-control"
                        />

                        <ErrorMessage
                          component={"div"}
                          name="plan"
                          className="text-danger text-right"
                        />

                        <label className="form-label">
                          {t("price")} in{" "}
                          {configuration.get("configData.currency")} *
                        </label>
                        <Field
                          type="number"
                          min="0"
                          step="any"
                          placeholder={t("enter_the_price")}
                          value={values.amount}
                          name="amount"
                          className="form-control"
                        />

                        <ErrorMessage
                          component={"div"}
                          name="amount"
                          className="text-danger text-right"
                        />

                        <Button 
                          className="btn-continue btn-block"
                          // disabled={props.subCreate.buttonDisable}
                          disabled={
                            !values.amount || !values.title || !values.plan
                          }
                          type="submit"
                        >
                          {props.subCreate.loadingButtonContent != null
                            ? props.subCreate.loadingButtonContent
                            : t("submit")}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                  {/* <Form className="create-subscription-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("title")}*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("mathew_perry")}
                  value={inputData.title}
                  name="title"
                  autoFocus
                  onChange={(event) => {
                    setInputData({
                      ...inputData,
                      title: event.currentTarget.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("duration")}*</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={t("enter_the_Duration_in_mins")}
                  value={inputData.plan}
                  name="plan"
                  min="1"
                  step="any"
                  onChange={(event) => {
                    setInputData({
                      ...inputData,
                      plan: event.currentTarget.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("price")} in {configuration.get("configData.currency")} *</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  step="any"
                  placeholder={t("enter_the_price")}
                  value={inputData.amount}
                  name="amount"
                  onChange={(event) => {
                    setInputData({
                      ...inputData,
                      amount: event.currentTarget.value,
                    });
                  }}
                />
              </Form.Group>
              <Button
                className="btn-continue btn-block"
                // disabled={props.subCreate.buttonDisable}
                disabled={!inputData.amount || !inputData.title || !inputData.plan}
                type="submit"
              >
                {props.subCreate.loadingButtonContent != null
                  ? props.subCreate.loadingButtonContent
                  : t("submit")}
              </Button>
            </Form> */}
                </div>
              </div>
            </div>
          ) : (
            <>
              <h6 className="text-center text-white">
                {t("please_add_kyc_document")}
              </h6>
              <Link to="/kyc-documents">
                <div className="col-md-12 text-center">
                  <Button className="btn btn-success">
                    {t("add_kyc_documents")}
                  </Button>
                </div>
              </Link>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  subCreate: state.subscriptions.subCreate,
  kycDocDetails: state.kycDocument.kycDocDetails,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(CreateSubscription));
