import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";
import "./BillingAccount.css";
import { viewBankAccountStart, addBankAccountStart } from "../../../store/actions/BankAccountAction";
import Skeleton from "react-loading-skeleton";

const EditBillingAccount = (props) => {

  const [inputData, setInputData] = useState({});
  const [skipRender, setSkipRender] = useState(true);

  const addbillingSchema = Yup.object().shape({
    route_number: Yup.string()
      .required(t("route_number_is_required")),
    account_number: Yup.string()
      .required(t("account_number_is_required")),
    first_name: Yup.string()
      .required(t("first_name_is_required")),
    last_name: Yup.string()
      .required(t("last_name_is_required")),
  });
  useEffect(() => {
    props.dispatch(
      viewBankAccountStart({
        user_billing_account_unique_id:
          props.match.params.user_billing_account_unique_id,
      })
    );
  }, []);

  useEffect(() => {
    if (!skipRender && !props.viewAccount.loading && Object.keys(props.viewAccount.data).length > 0) {
      setInputData(props.viewAccount.data.billing_account);
    }
    setSkipRender(false);
  }, [props.viewAccount]);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(addBankAccountStart(inputData));
  };

  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="add-bank-card">
            <h3 className="title">{t("add_billing_account")}</h3>
            {props.viewAccount.loading ?
              (<Row>
                <Col md={6} className="mb-3">
                  <Skeleton count={3} height={55} width={510} />
                </Col>
                <Col md={6} className="mb-3">
                  <Skeleton count={3} height={55} width={510} />
                </Col>
              </Row>)
              :
              <Formik validationSchema={addbillingSchema} >
                {({ errors, touched }) => (
                  <FORM onSubmit={handleSubmit}
                    className="add-bank-form"
                  >
                    <Row>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("route_number")}</label>
                        <Field
                          type="text"
                          placeholder={t("route_number_placeholder")}
                          name="route_number"
                          className="form-control"
                          value={inputData.route_number}
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              route_number: event.currentTarget.value,
                            });
                          }}
                        />
                        {!inputData.route_number ? <ErrorMessage component={"div"} name="route_number" className='text-danger text-right' /> : null}
                      </Col>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("account_number")}</label>
                        <Field
                          type="text"
                          placeholder={t("account_number_placeholder")}
                          name="account_number"
                          className="form-control"
                          value={inputData.account_number}
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              account_number: event.currentTarget.value,
                            });
                          }}
                        />
                        {!inputData.account_number ? <ErrorMessage component={"div"} name="account_number" className='text-danger text-right' /> : null}
                      </Col>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("first_name")} *</label>
                        <Field
                          type="text"
                          placeholder={t("first_name_placeholder")}
                          name="first_name"
                          className="form-control"
                          value={inputData.first_name}
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              first_name: event.currentTarget.value,
                            });
                          }}
                        />
                        {!inputData.first_name ? <ErrorMessage component={"div"} name="first_name" className='text-danger text-right' /> : null}
                      </Col>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("last_name")} *</label>
                        <Field
                          type="text"
                          placeholder={t("last_name_placeholder")}
                          name="last_name"
                          className="form-control"
                          value={inputData.last_name}
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              last_name: event.currentTarget.value,
                            });
                          }}
                        />
                        {!inputData.last_name ? <ErrorMessage component={"div"} name="last_name" className='text-danger text-right' /> : null}
                      </Col>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("bank_type")} *</label>
                        <Field
                          as="select"
                          placeholder={t("bank_type_placeholder")}
                          name="bank_type"
                          className="form-control"
                          value={inputData.bank_type}
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              bank_type: event.currentTarget.value,
                            });
                          }}
                        >
                          <option value="savings">{t("savings")}</option>
                          <option value="checking">{t("checking")}</option>
                        </Field>
                      </Col>
                      <Col md={6} className="mb-3">
                        <label className="form-label">{t("business_name")}</label>
                        <Field
                          type="text"
                          placeholder={t("business_name_placeholder")}
                          name="business_name"
                          className="form-control"
                          value={inputData.business_name}
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              business_name: event.currentTarget.value,
                            });
                          }}
                        />
                        <ErrorMessage component={"div"} name="business_name" className='text-danger text-right' />
                      </Col>
                      <Col>
                        <div className="btn-continue-sec">
                          <Button
                            className="btn-continue"
                            type="submit"
                            disabled={
                              !inputData.route_number ||
                              !inputData.account_number ||
                              !inputData.first_name ||
                              !inputData.last_name
                            }
                          >
                            {props.bankAccount.loadingButtonContent !== null
                              ? props.bankAccount.loadingButtonContent
                              : t("submit")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </FORM>
                )}
              </Formik>
            }
          </div>
        </Container>
      </div >
    </>
  )

}

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.addBankAccountInput,
  viewAccount: state.bankAccount.viewAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(EditBillingAccount));