import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import MobileFilterCategory from "./MobileFilterCategory";
import "./FilterView.css";
import { t, withTranslation } from "react-multi-lang";

const FilterViewHeader = (props) => {
  useEffect(() => {}, []);

  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <>
      <div className="filter-view-sec">
        <div className="filter-view-header-sec">
          <h2>{t("featured")}</h2>
          <div className="filter-view-btn-action-sec">
            <Button
              className="btn-hide-filter"
              onClick={() => props.setIsFilterToggle(!props.isFilterToggle)}
            >
             {(!props.isFilterToggle) ? t("view_filter") : t("hide_filter")}
            </Button>
            <Button className="btn-clear-filter" onClick={props.clearFilter}>
            {t("clear_filter")}
            </Button>
          </div>
          <div className="filter-view-btn-action-sec mobile">
            <Button
              className="btn-hide-filter"
              onClick={() => setIsVisible(!isVisible)}
            >
             {t("sort_filter")}
            </Button>
          </div>
          {isVisible && (
            <div>
              <MobileFilterCategory
                filterInputData={props.filterInputData}
                setFilterInputData={props.setFilterInputData}
                filterOption={props.filterOption}
              />
              <div
                className="close-icon-sec"
                onClick={() => setIsVisible(!isVisible)}
              >
                <i class="fas fa-times"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(FilterViewHeader));
