import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Row } from 'react-bootstrap';

const ModelPostLoader = () => {

  return (
    <Row>
      {[...Array(6)].map(() =>
        <Col md={4} className="resp-mrg-btm-xs">
          <Skeleton className="video-post-img" />
        </Col>
      )}
    </Row>
  );
}

export default ModelPostLoader;