import {
  BOOKING_FILE_UPLOAD_START,
  BOOKING_SAVE_SUCCESS,
  BOOKING_FILE_UPLOAD_SUCCESS,
  BOOKING_FILE_UPLOAD_FAILURE,
  BOOKING_SAVE_START,
  BOOKING_SAVE_FAILURE,
  BOOKING_CANCEL_START,
  BOOKING_CANCEL_SUCCESS,
  BOOKING_CANCEL_FAILURE,
  FETCH_BOOKINGS_START,
  FETCH_MORE_BOOKING_START,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_FAILURE,
  FETCH_SINGLE_BOOKING_START,
  FETCH_SINGLE_BOOKING_SUCCESS,
  FETCH_SINGLE_BOOKING_FAILURE,
  UPDATE_USER_REVIEW_START,
  UPDATE_USER_REVIEW_SUCCESS,
  UPDATE_USER_REVIEW_FAILURE,
} from "./ActionConstant";

export function bookingFileUploadStart(data) {
  return {
    type: BOOKING_FILE_UPLOAD_START,
    data,
  };
}

export function bookingFileUploadSuccess(data) {
  return {
    type: BOOKING_FILE_UPLOAD_SUCCESS,
    data,
  };
}

export function bookingFileUploadFailure(error) {
  return {
    type: BOOKING_FILE_UPLOAD_FAILURE,
    error,
  };
}

export function bookingSaveStart(data) {
  return {
    type: BOOKING_SAVE_START,
    data,
  };
}

export function bookingSaveSuccess(data) {
  return {
    type: BOOKING_SAVE_SUCCESS,
    data,
  };
}

export function bookingSaveFailure(error) {
  return {
    type: BOOKING_SAVE_FAILURE,
    error,
  };
}

export function bookingCancelStart(data) {
  return {
    type: BOOKING_CANCEL_START,
    data,
  };
}

export function bookingCancelSuccess(data) {
  return {
    type: BOOKING_CANCEL_SUCCESS,
    data,
  };
}

export function bookingCancelFailure(error) {
  return {
    type: BOOKING_CANCEL_FAILURE,
    error,
  };
}

export function fetchBookingsStart(data) {
  return {
    type: FETCH_BOOKINGS_START,
    data,
  };
}

export function fetchMoreBookingsStart(data) {
  return {
    type: FETCH_MORE_BOOKING_START,
    data,
  };
}

export function fetchBookingsSuccess(data) {
  return {
    type: FETCH_BOOKINGS_SUCCESS,
    data,
  };
}

export function fetchBookingsFailure(error) {
  return {
    type: FETCH_BOOKINGS_FAILURE,
    error,
  };
}

export function fetchSingleBookingStart(data) {
  return {
    type: FETCH_SINGLE_BOOKING_START,
    data,
  };
}

export function fetchSingleBookingSuccess(data) {
  return {
    type: FETCH_SINGLE_BOOKING_SUCCESS,
    data,
  };
}

export function fetchSingleBookingFailure(error) {
  return {
    type: FETCH_SINGLE_BOOKING_FAILURE,
    error,
  };
}

export function postReviewUpdateStart(data) {
  return {
    type: UPDATE_USER_REVIEW_START,
    data,
  };
}

export function postReviewUpdateSuccess(data) {
  return {
    type: UPDATE_USER_REVIEW_SUCCESS,
    data,
  };
}

export function postReviewUpdateFailure(error) {
  return {
    type: UPDATE_USER_REVIEW_FAILURE,
    error,
  };
}