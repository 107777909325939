import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Media,
  Nav,
  Tab,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { connect } from "react-redux";
import JoinVideoCallModal from "../Helper/JoinVideoCallModal";
import MyTicketsTable from "./MyTicketsTable";
import { fetchMoreVideocallUserStart, fetchVideocallUserStart } from "../../store/actions/VideoCall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHighlighter,
  faInbox,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { t, withTranslation } from "react-multi-lang";

const MyTicketsIndex = (props) => {

  const [activeTab, setActiveTab] = useState("upcoming");

  const [joinVideoCall, setJoinVideoCall] = useState(false);

  const [search, setSearch] = useState("");

  const closeJoinVideoCallModal = () => {
    setJoinVideoCall(false);
  };

  const changeTab = (tab) => {
    setActiveTab(tab);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    props.dispatch(fetchVideocallUserStart({
      status: activeTab,
      skip: 0,
      take: 12,
      search_key: search,
    }));
  }, [activeTab, search]);

  const fetchMore = () => {
    props.dispatch(fetchMoreVideocallUserStart({
      status: activeTab,
      skip: props.userVideoCall.data.video_calls.length,
      take: 12,
    }));
  }

  return (
    <>
      <div className="events-wrapper-sec">
        <div className="events-sidebar-sec">
          <ul className="events-list-sec list-unstyled">
            <Media as="li">
              <Link to="/events">
                <Image
                  src="assets/images/search-white-icon.svg"
                  alt="user-image"
                  className="search-siderbar-icon"
                />
                {t("explore_events")}
              </Link>
            </Media>
            <Media as="li">
              <Link to="/my-tickets" className="active">
                <Image
                  src="assets/images/live-tickets-icon.svg"
                  alt="user-image"
                  className="search-siderbar-icon"
                />
                {t("my_tickets")}
              </Link>
            </Media>
            {localStorage.getItem("is_model") == "2" &&
              <Media as="li">
                <Link to="/incoming-video-call">
                  <Image
                    src="assets/images/video-icon.svg"
                    alt="user-image"
                    className="search-siderbar-icon"
                  />
                  {t("incoming_video_call")}
                </Link>
              </Media>
            }
          </ul>
        </div>
        <div className="events-right-main-content">
          <div className="live-events-header-sec">
            <h2>{t("my_live_video_calls")}</h2>
            <div className="live-event-looking-sec">
              <div className="resp-flex-content text-white mb-3">
                <span>{t("manage_all_the_request_sent_to_your_favourite_celebrities")}</span>
              </div>

              <div className="event-search">
                <InputGroup className="search-header-bar">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <FontAwesomeIcon icon={faSearch} color="gray" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <FormControl
                    onChange={e => setSearch(e.target.value)}
                    type="text"
                    value={search}
                    placeholder={t("search")}
                  />

                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{
                        border: "0",
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                        cursor: "pointer",
                      }}
                    >
                      {search != "" && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="green"
                          onClick={() => setSearch("")}
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </div>
            </div>
            <div className="my-live-video-call-sec">
              <Tab.Container id="left-tabs-example" defaultActiveKey="upcoming">
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="upcoming"
                          onClick={() => changeTab("upcoming")}
                        >{t("upcoming")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="completed"
                          onClick={() => changeTab("completed")}
                        >{t("completed")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="declined"
                          onClick={() => changeTab("declined")}
                        >{t("declined")}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    {activeTab === "upcoming" &&
                      <MyTicketsTable
                        isAction={true}
                        fetchMoreData={fetchMore}
                        videoCall={props.userVideoCall}
                        isSent={true}
                      />
                    }
                    {activeTab === "completed" &&
                      <MyTicketsTable
                        isAction={false}
                        fetchMoreData={fetchMore}
                        videoCall={props.userVideoCall}
                        isSent={true}
                      />
                    }
                    {activeTab === "declined" &&
                      <MyTicketsTable
                        isAction={false}
                        fetchMoreData={fetchMore}
                        videoCall={props.userVideoCall}
                        isSent={true}
                      />
                    }
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      <JoinVideoCallModal
        joinVideoCall={joinVideoCall}
        closeJoinVideoCallModal={closeJoinVideoCallModal}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  userVideoCall: state.videocall.userVideoCall,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(MyTicketsIndex));
