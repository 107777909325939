import React, { useEffect } from 'react';
import ModelVideoSec from '../ModelProfile/ModelVideoSec';
import { connect } from 'react-redux';
import { t, withTranslation } from "react-multi-lang";
import { useParams } from 'react-router';
import {
  fetchModelPostsStart,
  fetchMoreModelPostsStart
} from '../../store/actions/ModelAction';
import { Row, } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import ModelPostLoader from '../Handlers/Loaders/ModelPostLoader';

const ModelPost = (props) => {

  const params = useParams();

  useEffect(() => {
    props.dispatch(
      fetchModelPostsStart({ model_username: params.username, skip: 0, take: 12 })
    );
  }, [params.username]);

  const fetchMorePosts = () => {
    props.dispatch(fetchMoreModelPostsStart({
      model_username: params.username,
      skip: props.modelPost.data.posts.length,
      take: 12,
    }));
  }

  return (
    <div className="single-filter-view-sec">
      <div className="single-filter-view-body-sec">
        <div className="video-post-sec">
          {props.modelPost.loading
            ? <ModelPostLoader />
            : props.modelPost.data.posts.length > 0 ?
              <InfiniteScroll
                dataLength={props.modelPost.data.posts.length}
                next={fetchMorePosts}
                hasMore={props.modelPost.data.posts.length < props.modelPost.data.total &&
                  props.modelPost.errorCount < 2}
                loader={<ModelPostLoader />}
                style={{ height: 'auto', overflow: 'hidden' }}
              >
                <Row>
                  {props.modelPost.data.posts.map((post) =>
                    <ModelVideoSec post={post} />
                  )}
                </Row>
              </InfiniteScroll>
              : ""}
        </div>
      </div>
    </div>
  )
}

const mapStateToPros = (state) => ({
  modelPost: state.model.modelPost,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(ModelPost));