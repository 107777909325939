import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Payment.css";
import {
  Container,
  Row,
  Col,
  Button,
  Media,
  Tab,
  Nav,
  Table,
  Image,
} from "react-bootstrap";
import PaymentCard from "./PaymentCard";
import { t, withTranslation } from "react-multi-lang";

const PaymentIndex = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="billing-account-sec main-wrapper">
        <Container>
          <Row>
            <Col md={12} xl={4} lg={4} className="resp-mrg-btm-md">
              <div className="profile-card">
                <div className="card-img">
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/booking-profile.jpg"
                    }
                    alt="user-image"
                    className="user-img"
                  />
                </div>
                <div className="card-body">
                  <ul className="list-unstyled profile-info">
                    <Media as="li">
                      <span>{t("name")}:</span>Alex Smith
                    </Media>
                    <Media as="li">
                      <span>{t("email")}:</span>alexsmith@gmail.com
                    </Media>
                    <Media as="li">
                      <span>{t("phone")}:</span>+ 00 222 44 5678
                    </Media>
                    <Media as="li">
                      <span>{t("country")}:</span>Australia
                    </Media>
                    <Media as="li">
                      <span>{t("city")}:</span>Sydney
                    </Media>
                    <Media as="li">
                      <span>{t("address")}:</span>Collins Street West, Victoria
                      8007,Australia.
                    </Media>
                    <Media as="li">
                      <span>{t("status")}:</span>
                      Active
                    </Media>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={12} xl={8} lg={8} className="resp-mrg-btm-xs">
              <div className="profile-card mb-4">
                <Row>
                  <Col sm={12}>
                    <h3>Earning</h3>
                    <div className="table-form table-responsive">
                      <Table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{t("date")}</th>
                            <th scope="col">{t("transaction_id")}</th>
                            <th scope="col">{t("mode")}</th>
                            <th scope="col">{t("description")}</th>
                            <th scope="col">{t("amount")}</th>
                            <th scope="col">{t("status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>09 Dec 2020</td>
                            <td>WPP-1690737828</td>
                            <td>Wallet</td>
                            <td>Money sent from wallet</td>
                            <td>-$10.00</td>
                            <td>
                              <span className="badge badge-success py-1 px-2">
                                Paid
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>09 Dec 2020</td>
                            <td>WPP-1690737828</td>
                            <td>Wallet</td>
                            <td>Money sent from wallet</td>
                            <td>-$10.00</td>
                            <td>
                              <span className="badge badge-warning text-white py-1 px-2">
                                pending
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>09 Dec 2020</td>
                            <td>WPP-1690737828</td>
                            <td>Wallet</td>
                            <td>Money sent from wallet</td>
                            <td>-$10.00</td>
                            <td>
                              <span className="badge badge-success py-1 px-2">
                                Paid
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="btn-view-more-sec">
                      <Button className="btn-view-more">
                        {t("view_more")}<i class="fas fa-chevron-right ml-2"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="profile-card">
                <Row>
                  <Col sm={12}>
                    <h3>{t("withdraw")}</h3>
                    <div className="table-form table-responsive">
                      <Table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{t("date")}</th>
                            <th scope="col">{t("transaction_id")}</th>
                            <th scope="col">{t("mode")}</th>
                            <th scope="col">{t("description")}</th>
                            <th scope="col">{t("amount")}</th>
                            <th scope="col">{t("status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>09 Dec 2020</td>
                            <td>WPP-1690737828</td>
                            <td>Wallet</td>
                            <td>Money sent from wallet</td>
                            <td>-$10.00</td>
                            <td>
                              <span className="badge badge-success py-1 px-2">
                                Paid
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>09 Dec 2020</td>
                            <td>WPP-1690737828</td>
                            <td>Wallet</td>
                            <td>Money sent from wallet</td>
                            <td>-$10.00</td>
                            <td>
                              <span className="badge badge-warning text-white py-1 px-2">
                                pending
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>09 Dec 2020</td>
                            <td>WPP-1690737828</td>
                            <td>Wallet</td>
                            <td>Money sent from wallet</td>
                            <td>-$10.00</td>
                            <td>
                              <span className="badge badge-success py-1 px-2">
                                Paid
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="btn-view-more-sec">
                      <Button className="btn-view-more">
                       {t("view_more")}<i class="fas fa-chevron-right ml-2"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(PaymentIndex));
