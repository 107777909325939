import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Payment.css";
import { Container, Row, Col, Button, Form, Badge } from "react-bootstrap";
import PaymentCard from "./PaymentCard";
import PaymentLoader from "../Handlers/Loaders/PaymentLoader";
import configuration from "react-global-configuration";
import { t, withTranslation } from "react-multi-lang";



const PaymentInvoice = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="main-wrapper">
        <div className="request-booking-sec">
          <Form className="request-bread">
            <span>1. {t("account")}</span>
            <span>2. {t("request")}</span>
            <span className="active">3. {t("payment")}</span>
          </Form>
          <PaymentCard />
          <small className="request-booking-bottom-text">
           {t("by_booking_you_agree_to")} {configuration.get("configData.site_name")}
            's
            <a href="/page/terms" target="_blank">
             {t("terms_of_service")}
            </a>
            <span>
              {" "}
              and
              <a href="/page/privacy" target="_blank">
                {t("privacy_policy")}
              </a>
            </span>
          </small>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(PaymentInvoice));
