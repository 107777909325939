import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Card.css";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Image,
} from "react-bootstrap";
import { t, withTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCardModelSec from "../../Helper/AddCardModelSec";
import configuration from "react-global-configuration";
import {
  deleteCardStart,
  fetchCardDetailsStart,
  selectDefaultCardStart,
} from "../../../store/actions/CardAction";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const CardsIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchCardDetailsStart());
  }, []);

  const stripePromise = loadStripe(
    configuration.get("configData.stripe_publishable_key")
  );

  const [addCard, setAddCard] = useState(false);

  const closeAddCardModal = () => {
    setAddCard(false);
  };

  const [paymentAddCard, setPaymentAddCard] = useState(false);

  const closePaymentAddCardModal = () => {
    setPaymentAddCard(false);
  };

  const { cards } = props;


  const defaultCard = (event, user_card_id) => {
    event.preventDefault();
    props.dispatch(
      selectDefaultCardStart({
        user_card_id: user_card_id,
      })
    )
  };


  const deleteCard = (event, user_card_id) => {
    event.preventDefault();
    props.dispatch(
      deleteCardStart({
        user_card_id: user_card_id,
      })
    )
  };

  const makeDeleteCardConfirmation = (event, user_card_id) => {
    confirmAlert({
      message: t("do_you_want_to_delete_this_card"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteCard(event, user_card_id)
        },
        {
          label: t("no"),
        }
      ]
    });
  };


  const defaultCardConfirmation = (event, user_card_id) => {
    confirmAlert({
      message: t("are_you_sure_want_to_make_this_card_as_default_one"),
      buttons: [
        {
          label:  t("yes"),
          onClick: () => defaultCard(event, user_card_id)
        },
        {
          label:  t("no"),
        }
      ]
    });
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="card-sec">
          <Container>
            <Row>
              <Col md={6} xl={4}>
                <div
                  className="card-list-box cursor-pointer-link"
                  onClick={() => setPaymentAddCard(true)}
                >
                  <div className="add-account-sec">
                    <Image
                      src={
                        window.location.origin + "/assets/images/add-card.svg"
                      }
                      alt="user-image"
                      className="add-card-img"
                    />
                    <h5 className="cards-title">{t("add_card")}</h5>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {cards.loading ? (
                t("loading")
              ) : cards.data.cards.length > 0 ? (
                cards.data.cards.map((card) => (
                  <Col md={6} xl={4} className="resp-mrg-btm-xs mb-3">
                    <div className="list-card-box">
                      <h5 className="mb-4">XXXX XXXX XXXX {card.last_four}</h5>
                      <h4 className="text-muted">{card.card_holder_name}</h4>
                      <div className="card-footer-sec">
                        <div className="card-action-sec">
                          {card.is_default == 1 ? (
                            <>
                            <h4 className="text-muted">{t("default_card")}</h4>
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/card-default.svg"
                              }
                              alt="user-image"
                              className="card-default-img"
                            />
                            
                            </>
                          ) : (
                            <Link
                              className="card-link-text text-info"
                              onClick={(event) =>
                               defaultCardConfirmation(event,card.user_card_id)
                              }
                            >
                            {t("mark_as_default")}
                            </Link>
                          )}
                          <Link
                            to="#"
                            onClick={(event) =>
                              makeDeleteCardConfirmation(event,card.user_card_id)
                            }
                          >
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/card-delete.svg"
                              }
                              alt="user-image"
                              className="card-delete-img"
                            />
                          </Link>
                        </div>
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/credit-card-2.svg"
                          }
                          alt="user-image"
                          className="bottom-img-sec"
                        />
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <NoDataFound></NoDataFound>
              )}
            </Row>
          </Container>
        </div>
      </div>
      <Elements stripe={stripePromise}>
        <AddCardModelSec
          paymentAddCard={paymentAddCard}
          closePaymentAddCardModal={closePaymentAddCardModal}
        />
      </Elements>
    </>
  );
};

const mapStateToPros = (state) => ({
  cards: state.cards.cardDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(CardsIndex));
