import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Media,
  Nav,
  Tab,
  Dropdown,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { connect } from "react-redux";
import JoinVideoCallModal from "../Helper/JoinVideoCallModal";
import MyTicketsTable from "./MyTicketsTable";
import { fetchVideocallModelStart, fetchMoreVideocallModelStart } from "../../store/actions/VideoCall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHighlighter,
  faInbox,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { t, withTranslation } from "react-multi-lang";

const LiveVideoRequestReceivedIndex = (props) => {

  const [joinVideoCall, setJoinVideoCall] = useState(false);


  const filterOptions = [
    { label: t("all"), value: null },
    { label: t("accepted"), value: 1 },
    { label: t("paid"), value: 8 },
    { label: t("pending"), value: 0 },
    { label: t("rejected"), value: 2 },
    { label: t("expired"), value: 5 },
  ]

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    label: t("all"),
    value: null,
  });

  const closeJoinVideoCallModal = () => {
    setJoinVideoCall(false);
  };



  useEffect(() => {
    props.dispatch(fetchVideocallModelStart({
      skip: 0,
      take: 12,
      search_key: search,
      status: filter.value ? filter.value : "",
    }));
  }, [search, filter]);

  const fetchMore = () => {
    props.dispatch(fetchMoreVideocallModelStart({
      skip: props.modelVideoCall.data.video_calls.length,
      take: 12,
    }));
  }

  return (
    <>
      <div className="events-wrapper-sec">
        <div className="events-sidebar-sec">
          <ul className="events-list-sec list-unstyled">
            <Media as="li">
              <Link to="/events">
                <Image
                  src="assets/images/search-white-icon.svg"
                  alt="user-image"
                  className="search-siderbar-icon"
                />
               {t("explore_events")}
              </Link>
            </Media>
            <Media as="li">
              <Link to="/my-tickets">
                <Image
                  src="assets/images/live-tickets-icon.svg"
                  alt="user-image"
                  className="search-siderbar-icon"
                />
               {t("my_tickets")}
              </Link>
            </Media>
            {localStorage.getItem("is_model") == "2" &&
              <Media as="li">
                <Link to="/incoming-video-call" className="active">
                  <Image
                    src="assets/images/video-icon.svg"
                    alt="user-image"
                    className="search-siderbar-icon"
                  />
                  {t("incoming_video_call")}
                </Link>
              </Media>
            }
          </ul>
        </div>
        <div className="events-right-main-content">
          <div className="live-events-header-sec">
            <h2>{t("my_incoming_video_calls")}</h2>
            <div className="live-event-looking-sec">
              <div className="resp-flex-content text-white">
                <span>{t("manage_all_your_request_received_by_your_fans")}</span>
              </div>
              <div className="event-search">
                <Dropdown className="mr-2">
                  <Dropdown.Toggle
                    className="header-dropdown dropdown-bg"
                    id="dropdown-basic"
                  >
                    <div className="user-dropdown-img-sec">
                      {filter.label}
                    </div>
                    <i className="fas fa-chevron-down header-drop-icon"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="header-dropdown-menu">
                    <ul className="list-unstyled header-drop-down-item">
                      {filterOptions.map((option, i) =>
                        <Media as="li" className="text-white" onClick={() => setFilter(option)}>{option.label}</Media>
                      )}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                <InputGroup className="search-header-bar">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <FontAwesomeIcon icon={faSearch} color="gray" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <FormControl
                    onChange={e => setSearch(e.target.value)}
                    type="text"
                    value={search}
                    placeholder="Search Model..."
                  />

                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{
                        border: "0",
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                        cursor: "pointer",
                      }}
                    >
                      {search != "" && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="green"
                          onClick={() => setSearch("")}
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </div>
            </div>
            <div className="my-live-video-call-sec">
              <Tab.Container id="left-tabs-example" defaultActiveKey="upcoming">
                <Row>
                  {/* <Col sm={12}>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="upcoming"
                          onClick={() => changeTab("upcoming")}
                        >Upcoming</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="completed"
                          onClick={() => changeTab("completed")}
                        >Completed</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="declined"
                          onClick={() => changeTab("declined")}
                        >Declined</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col> */}
                  <Col sm={12}>
                    <MyTicketsTable
                      isAction={true}
                      fetchMoreData={fetchMore}
                      videoCall={props.modelVideoCall}
                      isSent={false}
                    />
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      <JoinVideoCallModal
        joinVideoCall={joinVideoCall}
        closeJoinVideoCallModal={closeJoinVideoCallModal}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  modelVideoCall: state.videocall.modelVideoCall,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(LiveVideoRequestReceivedIndex));
