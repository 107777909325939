import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Image, Col, Media } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t, withTranslation } from "react-multi-lang";
import {
  faHighlighter,
  faInbox,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

import "./Inbox.css";

const InboxIndex = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="inbox-sec main-wrapper">
        <Container>
          <Row>
            <Col lg={4} className="padding-right-none">
              <div className="people-list dashboard-bread-2" id="style-2">
                <div className="search" style={{ display: "none" }}>
                  <input type="text" placeholder="search" />
                  <i className="fa fa-search"></i>
                </div>
                <div className="people-list-header">
                  <h3 className="title font-size-24 text-white">{t("chat_list")}</h3>
                </div>
                <ul className="list">
                  <Media as="li" className="active">
                    <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">{t("beno_darry")}</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        {t("online")}
                      </div>
                    </div>
                  </Media>
                  <Media as="li">
                    <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">{t("jerif_daniel")}</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="offline" />{" "}
                       {t("left_7_mins_ago")}
                      </div>
                    </div>
                  </Media>
                  <Media as="li">
                    <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">{t("jeno")}</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        {t("online")}
                      </div>
                    </div>
                  </Media>
                  <Media as="li">
                    <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">{t("suderson")}</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        {t("online")}
                      </div>
                    </div>
                  </Media>
                  <Media as="li">
                    <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">{t("ruban")}</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        {t("online")}
                      </div>
                    </div>
                  </Media>
                  <Media as="li">
                    <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">{t("benwhiteson")}</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="offline" />{" "}
                      {t("left_30_mins_ago")}
                      </div>
                    </div>
                  </Media>
                  <Media as="li">
                    <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">{t("beaulet")}</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="offline" />{" "}
                       {t("left_10_hours_ago")}
                      </div>
                    </div>
                  </Media>
                  <Media as="li">
                    <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">{t("vinoth")}</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        {t("online")}
                      </div>
                    </div>
                  </Media>
                  <Media as="li">
                    <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">{t("pinto")}</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="offline" />{" "}
                       {t("offline_since_oct_28")}
                      </div>
                    </div>
                  </Media>
                  <Media as="li">
                    <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">{t("rufus")}</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                       {t("online")}
                      </div>
                    </div>
                  </Media>
                </ul>
              </div>
            </Col>
            <Col lg={8} className="padding-left-none">
              <div className="chat">
                <div className="chat-header clearfix">
                  <Image
                      src={
                      window.location.origin +
                      "/assets/images/img-2.jpg"
                      }
                      alt="no image"
                      className="chat-profile-img"
                  />
                  <div className="chat-about">
                    <div className="chat-with">{t("chat_with_beno_darry")}</div>
                    <div className="chat-num-messages">
                     {t("already_messages")}
                    </div>
                  </div>
                  <i className="fa fa-star"></i>
                </div>
                <div className="chat-history" id="style-2">
                  <ul>
                    <Media as="li">
                      <div className="message-data align-right">
                        <span className="message-data-time">
                          10:10 AM, {t("today")}
                        </span>{" "}
                        &nbsp; &nbsp;
                        <span className="message-data-name">{t("jeffy")}</span>{" "}
                        <i className="fa fa-circle me"></i>
                      </div>
                      <div className="message other-message float-right">
                      {t("index_text_message")}
                      </div>
                    </Media>
                    <Media as="li">
                      <div className="message-data">
                        <span className="message-data-name">
                          <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                          {t("beno_darry")}
                        </span>
                        <span className="message-data-time">
                          10:12 AM, {t("today")}
                        </span>
                      </div>
                      <div className="message my-message">
                      {t("index_message_one")}
                      </div>
                    </Media>
                    <Media as="li">
                      <div className="message-data align-right">
                        <span className="message-data-time">
                          10:14 AM, {t("today")}
                        </span>{" "}
                        &nbsp; &nbsp;
                        <span className="message-data-name">{t("jeffy")}</span>{" "}
                        <i className="fa fa-circle me"></i>
                      </div>
                      <div className="message other-message float-right">
                       {t("index_other_message")}
                      </div>
                    </Media>
                    <Media as="li">
                      <div className="message-data">
                        <span className="message-data-name">
                          <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                          {t("beno_darry")}
                        </span>
                        <span className="message-data-time">
                          10:20 AM, {t("today")}
                        </span>
                      </div>
                      <div className="message my-message">
                       {t("index_message")}
                      </div>
                    </Media>
                  </ul>
                </div>
                <div className="chat-message clearfix">
                  <textarea
                    name="message-to-send"
                    id="message-to-send"
                    placeholder="Type your message"
                    rows="3"
                  ></textarea>
                  <div className="chat-send-btm-sec">
                    <button
                      className="theme-btn theme-btn-small submit-btn"
                      type="submit"
                    >
                      <i className="la la-paper-plane mr-1"></i>{t("send")}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(InboxIndex));
