import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./BillingAccount.css";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { t, withTranslation } from "react-multi-lang";
import {
  deleteBankAccountStart,
  getBankAccountStart,
  makeDefaultBankAccountStart,
} from "../../../store/actions/BankAccountAction";
import { deleteAccountStart } from "../../../store/actions/UserAction";
import BillingAccountLoader from "../../Handlers/Loaders/BillingAccountLoader";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const BillingAccountsIndex = (props) => {
  useEffect(() => {
    if (props.bankAccount.loading) props.dispatch(getBankAccountStart());
  }, []);

  const makeDefault = (event, user_billing_account_id) => {
    event.preventDefault();
    props.dispatch(
      makeDefaultBankAccountStart({
        user_billing_account_id: user_billing_account_id,
      })
    );
  };

  const deleteAccount = (event, user_billing_account_id) => {
    event.preventDefault();
    props.dispatch(
      deleteBankAccountStart({
        user_billing_account_id: user_billing_account_id,
      })
    );
  };


  const makeDefaultConfirmation = (event, user_billing_account_id) => {
    confirmAlert({
      message: t("are_you_sure_want_to_make_this_as_default"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => makeDefault(event, user_billing_account_id)
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  const deleteConfirmation = (event, user_billing_account_id) => {
    confirmAlert({
      message: t("are_you_sure_Want_to_delete_this_account"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteAccount(event, user_billing_account_id)
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="bank-account-list-header-sec">
          <div className="bank-account-list-flex">
            <h3 className="title">{t("billing_account")}</h3>
            <p className="desc">
              {t("billing_account_note")}
            </p>
          </div>
          <div className="bank-account-list-btn">
            <Link to="/add-billing-account" className="add-new-account-btn">
              {t("add_new_account")}
            </Link>
          </div>
        </div>
        <Container>
          <div className="bank-account-list-card">
            <Row>
              <Col md={12}>
                {props.bankAccount.loading
                  ? <BillingAccountLoader />
                  : props.bankAccount.data.billing_accounts.length > 0
                    ?
                    <Table responsive className="bank-account-list-table">
                      <thead>
                        <tr>
                          <th>{t("first_name")} </th>
                          <th>{t("last_name")}</th>
                          <th>{t("account_numbers")}</th>
                          <th>{t("route_number_placeholder")}</th>
                          <th>{t("bank_type")}</th>
                          <th>{t("business_name_placeholder")}</th>
                          <th>{t("action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.bankAccount.data.billing_accounts.map(
                          (account) => (

                            <tr>
                              <td>{account.first_name}</td>
                              <td>{account.last_name}</td>
                              <td>{account.account_number}</td>
                              <td className="amount">{account.route_number}</td>
                              <td>{account.bank_type}</td>
                              <td>{account.business_name}</td>
                              <td>
                                <Link className="edit-bank-account" to={`/edit-billing-account/` +
                                  account.user_billing_account_unique_id}>{t("edits")}</Link>
                                {account.is_default === 0 ? (
                                  <Button
                                    type="button"
                                    className="btn-theme-default"
                                    onClick={(event) =>
                                      makeDefaultConfirmation(event,
                                        account.user_billing_account_id)}
                                  >
                                    {t("make_default")}
                                  </Button>
                                ) : null}
                                <Button
                                  type="button"
                                  className="btn-theme-delete"
                                  onClick={(event) =>
                                    deleteConfirmation(
                                      event,
                                      account.user_billing_account_id
                                    )
                                  }
                                >
                                  {t("delete")}
                                </Button>
                              </td>
                            </tr>
                          )
                        )
                        }
                      </tbody>
                    </Table> : <NoDataFound />}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.bankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(BillingAccountsIndex));
