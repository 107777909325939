import React from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
  Table,
} from "react-bootstrap";
import configuration from "react-global-configuration";
import { t, withTranslation } from "react-multi-lang";

const ModelStaticSec = () => {
  return (
    <div className="single-filter-footer-sec">
      <Row>
        <Col md={12} lg={6} xl={6}>
          <div className="single-filter-about-sec">
            <h4 className="title">
              {t("what_is")} {configuration.get("configData.site_name")}?
            </h4>
            <Row>
              <Col md={4} xs={4} className="resp-mrg-btm-xs">
                <div className="about-item">
                  <div className="icon-sec">
                    <Image
                      src="assets/images/mobile-icon.svg"
                      alt="user-image"
                      className="about-icons"
                    />
                  </div>
                  <h4 className="desc">{t("send_your_request")}</h4>
                </div>
              </Col>
              <Col md={4} xs={4} className="resp-mrg-btm-xs">
                <div className="about-item">
                  <div className="icon-sec">
                    <Image
                      src="assets/images/video-icon.svg"
                      alt="user-image"
                      className="about-icons"
                    />
                  </div>
                  <h4 className="desc">{t("get_your_video")}</h4>
                </div>
              </Col>
              <Col md={4} xs={4}>
                <div className="about-item">
                  <div className="icon-sec">
                    <Image
                      src="assets/images/thumsup.svg"
                      alt="user-image"
                      className="about-icons"
                    />
                  </div>
                  <h4 className="desc">{t("make_their_year")}</h4>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={12} lg={6} xl={6}>
          <div className="single-filter-about-sec">
            <h4 className="title-1">
              {t("what_does_a_good_request_look_like")}
            </h4>
            <h5 className="sub-title">{t("tip_1")}</h5>
            <p className="desc">{t("tip_1_content")}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation(ModelStaticSec);
