import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Modal,
  Col,
  Row,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import { useHistory } from 'react-router';
import { t, withTranslation } from "react-multi-lang";

const JoinVideoCallModal = (props) => {

  const history = useHistory();

  const [otp, setOTP] = useState("");

  const handleChange = (otp) => {
    setOTP(otp);
    setErrorMsg("");
  };

  const [errorMsg, setErrorMsg] = useState("");

  const joinCall = (e) => {
    console.log(otp);
    console.log(props.videoCall.ticket);
    e.preventDefault();
    if (otp === props.videoCall.ticket) {
      history.push(`/live-streaming/${props.videoCall.video_call_unique_id}`);
    } else {
      setErrorMsg(t("invalid_code"));
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-center payment-add-card-modal join-video-call-modal"
        size="md"
        centered
        show={props.joinVideoCall}
        onHide={props.closeJoinVideoCallModal}
      >
        <Form onSubmit={joinCall}>
          <Modal.Header closeButton>
            <Modal.Title>{t("join_video_call")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="">
              <Col md={12}>
                <div className="verification-box">
                  <p>{t("enter_the_ticket_to_join_the_call")}</p>
                  <div className="verification-item">
                    <OtpInput
                      shouldAutoFocus={true}
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      separator={<span>-</span>}
                      
                    />
                  </div>
                </div>
              </Col>
              <div className="verify-messages-error text-danger small">{errorMsg}</div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-danger width-btn"
              data-dismiss="modal"
              onClick={props.closeJoinVideoCallModal}
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              className="btn btn-success width-btn"
              data-dismiss="modal"
            >
              {t("join")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(withTranslation(JoinVideoCallModal));
