import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Image,
    Media,
} from "react-bootstrap";
import { connect } from "react-redux";
import Slider from "react-slick";

const AllPastEventsSlider = (props) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        adaptiveHeight: true,
        slidesToShow: 4,
    
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
    
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return (
        <>
            <div className="all-past-events-header-sec">
                <h4>All past events</h4>
            </div>
            <Slider {...settings} className="all-past-events-slider">
                <div className="all-past-events-items">
                    <div className="all-past-events-card">
                        <div className="all-past-events-img-sec">
                            <Image
                                src={
                                    window.location.origin + "/assets/images/live-events/live-events-1.jpg"
                                  }
                                alt="live-events-img"
                                className="all-past-events-img"
                            />
                        </div>
                        <div className="all-past-events-info">
                            <div className="all-past-events-status">
                                Event Ended
                            </div>
                            <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                            <div className="all-past-events-footer">
                                <p>Missed out on this event?</p>
                                <Link to="#">
                                    Get notified
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-items">
                    <div className="all-past-events-card">
                        <div className="all-past-events-img-sec">
                            <Image
                                 src={
                                    window.location.origin + "/assets/images/live-events/live-events-2.jpg"
                                  }
                                alt="live-events-img"
                                className="all-past-events-img"
                            />
                        </div>
                        <div className="all-past-events-info">
                            <div className="all-past-events-status">
                                Event Ended
                            </div>
                            <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                            <div className="all-past-events-footer">
                                <p>Missed out on this event?</p>
                                <Link to="#">
                                    Get notified
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-items">
                    <div className="all-past-events-card">
                        <div className="all-past-events-img-sec">
                            <Image
                                src={
                                    window.location.origin + "/assets/images/live-events/live-events-3.jpg"
                                  }
                                alt="live-events-img"
                                className="all-past-events-img"
                            />
                        </div>
                        <div className="all-past-events-info">
                            <div className="all-past-events-status">
                                Event Ended
                            </div>
                            <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                            <div className="all-past-events-footer">
                                <p>Missed out on this event?</p>
                                <Link to="#">
                                    Get notified
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-items">
                    <div className="all-past-events-card">
                        <div className="all-past-events-img-sec">
                            <Image
                                 src={
                                    window.location.origin + "/assets/images/live-events/live-events-4.jpg"
                                  }
                                alt="live-events-img"
                                className="all-past-events-img"
                            />
                        </div>
                        <div className="all-past-events-info">
                            <div className="all-past-events-status">
                                Event Ended
                            </div>
                            <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                            <div className="all-past-events-footer">
                                <p>Missed out on this event?</p>
                                <Link to="#">
                                    Get notified
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="all-past-events-items">
                    <div className="all-past-events-card">
                        <div className="all-past-events-img-sec">
                            <Image
                                 src={
                                    window.location.origin + "/assets/images/live-events/live-events-5.jpg"
                                  }
                                alt="live-events-img"
                                className="all-past-events-img"
                            />
                        </div>
                        <div className="all-past-events-info">
                            <div className="all-past-events-status">
                                Event Ended
                            </div>
                            <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                            <div className="all-past-events-footer">
                                <p>Missed out on this event?</p>
                                <Link to="#">
                                    Get notified
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(null, mapDispatchToProps)(AllPastEventsSlider);
