import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

const ModelProfileLoader = (props) => (
  <Container>
    <Row className="d-flex justify-content-end">
      <Skeleton containerClassName="mr-2" width={78} height={38} />
      <Skeleton width={54} height={38} />
    </Row>
    <Row className="mt-3">
      <Col md={6}>
        <Skeleton height={480} />
      </Col>
      <Col md={6}>
        <Skeleton width={250} className="mb-2" />
        <Row className="d-flex justify-content-around mb-2">
          <Col>
            <Skeleton height={39} />
            <Skeleton width={100} />
          </Col>
          <Col>
            <Skeleton circle={true} height={56} width={56} containerClassName="float-right" />
          </Col>
        </Row>
        <Skeleton height={32} width={182} className="mb-2" />
        <div className="mb-3">
          <Skeleton count={3} />
        </div>

        <Skeleton className="mb-2" width={150} />
        <div>
          <Skeleton count={2} height={102} />
        </div>
      </Col>
    </Row>
  </Container>
)

export default ModelProfileLoader;