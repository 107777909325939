import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./FansFollowing.css";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserCardSec from "./UserCardSec";
import { fetchFollowersStart } from "../../store/actions/FollowAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import FollowingLoader from "../Handlers/Loaders/FollowingLoader";
import { t, withTranslation } from "react-multi-lang";

const Followers = (props) => {
  useEffect(() => {
    if (props.follower.loading) props.dispatch(fetchFollowersStart());
  }, []);
  return (
    <>
      <div className="main-wrapper">
        <Container>
          <h3 className="heading-title">{t("followers")}</h3>
          {props.follower.loading ? (
            <FollowingLoader/>
          ) : props.follower.data.followers.length > 0 ? (
            <div className="followers-sec">
              <Row>
                {props.follower.data.followers.map((follow) => (
                  <UserCardSec follow={follow} />
                ))}
              </Row>
              <Row>
                <Col md={12}>
                  <div className="btn-view-more-sec">
                    <Button className="btn-view-more">
                     {t("load_more")}<i class="fas fa-chevron-right ml-2"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <NoDataFound></NoDataFound>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  follower: state.follow.followers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(Followers));
