import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Media,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { connect } from "react-redux";
import Slider from "react-slick";
import AllPastEventsCard from "./AllPastEventsCard";
import AllPastEventsSlider from "./AllPastEventsSlider";
import AllUpCommingEventsCard from "./AllUpCommingEventsCard";
import AllUpCommingEventsSlider from "./AllUpCommingEventsSlider";
import { fetchModelDetailsStart, fetchMoreModelDetailsStart } from "../../store/actions/ModelAction";
import SingleUpcommingEventModelcard from "./SingleUpcommingEventModelCard";
import NoDataFound from "../NoDataFound/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import UpCommingEventModelLoader from "../Handlers/Loaders/UpCommingEventModelLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHighlighter,
  faInbox,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { t, withTranslation } from "react-multi-lang";
import { fetchEventModelListStart, fetchMoreEventModelListStart } from "../../store/actions/VideoCall";


const EventsIndex = (props) => {

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search === "" || search.length > 2) {
      props.dispatch(fetchEventModelListStart({
        skip: 0,
        take: 12,
        search_key: search,
      }));
    }
  }, [search]);

  const fetchModeModels = () => {
    props.dispatch(fetchMoreEventModelListStart({
      skip: props.model.data.models.length,
      take: 12,
      search_key: search,
    }))
  }

  return (
    <>
      <div className="events-wrapper-sec">
        <div className="events-sidebar-sec">
          <ul className="events-list-sec list-unstyled">
            <Media as="li">
              <Link to="/events" className="active">
                <Image
                  src="assets/images/search-white-icon.svg"
                  alt="user-image"
                  className="search-siderbar-icon"
                />
                {t("explore_events")}
              </Link>
            </Media>
            <Media as="li">
              <Link to="/my-tickets">
                <Image
                  src="assets/images/live-tickets-icon.svg"
                  alt="user-image"
                  className="search-siderbar-icon"
                />
               {t("my_tickets")}
              </Link>
            </Media>
            {localStorage.getItem("is_model") == "2" &&
              <Media as="li">
                <Link to="/incoming-video-call">
                  <Image
                    src="assets/images/video-icon.svg"
                    alt="user-image"
                    className="search-siderbar-icon"
                  />
                 {t("incoming_video_call")}
                </Link>
              </Media>
            }
          </ul>
        </div>
        <div className="events-right-main-content">
          <div className="live-events-header-sec">
            <h2>{t("discover_live_events")}</h2>
            <div className="live-event-looking-sec">
              <div className="resp-flex-content">
                <span>{t("find_your_celebrity_to_have_a_direct_interaction")}</span>
              </div>

              <div className="event-search">
                <InputGroup className="search-header-bar">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <FontAwesomeIcon icon={faSearch} color="gray" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <FormControl
                    onChange={e => setSearch(e.target.value)}
                    type="text"
                    value={search}
                    placeholder="Search Model..."
                  />

                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{
                        border: "0",
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                        cursor: "pointer",
                      }}
                    >
                      {search != "" && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="green"
                          onClick={() => setSearch("")}
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </div>

            </div>
          </div>
          {/* <AllPastEventsCard/> */}
          {/* <AllPastEventsSlider/> */}
          {/* <AllUpCommingEventsCard /> */}
          {/* <AllUpCommingEventsSlider/> */}
          {props.model.loading ?
            <div className="all-upcoming-events-box">
              <UpCommingEventModelLoader />
            </div>
            : props.model.data.models && props.model.data.models.length > 0 ?
              <InfiniteScroll
                dataLength={props.model.data.models.length}
                next={fetchModeModels}
                hasMore={props.model.data.models.length < props.model.data.total &&
                  props.model.errorCount < 2}
                loader={<div className="all-upcoming-events-box">
                  <UpCommingEventModelLoader />
                </div>}
              >
                <div className="all-upcoming-events-box">
                  {props.model.data.models.map((model, index) =>
                    <SingleUpcommingEventModelcard
                      model={model} />
                  )}
                </div>
              </InfiniteScroll>
              : <NoDataFound />
          }
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  model: state.videocall.eventModelList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(EventsIndex));
