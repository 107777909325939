import React from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
} from "react-bootstrap";
import configuration from "react-global-configuration";
import { t, withTranslation } from "react-multi-lang";

const HowItWorksModel = (props) => {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.howItWorksModal}
      onHide={props.handleCloseHowItWorksModal}
      className="review-modal time-line-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>
           {t("what_happens_when_i_request")}{" "}
            {configuration.get("configData.site_name")}?
          </h4>
        </Modal.Title>
        <i
          className="fas fa-times close-icon"
          onClick={props.handleCloseHowItWorksModal}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <div className="howitswork-wrapper">
          <div className="howitswork-row">
            <div className="howitswork-icon">
              <Image
                src={window.location.origin + "/assets/images/calendar.svg"}
                alt="user-image"
                className="time-line-img"
              />
            </div>
            <p className="time-line-desc">
             {t("your_request_will_be_completed_within_7_days")}
            </p>
          </div>
          <div className="howitswork-row">
            <div className="howitswork-icon">
              <Image
                src={window.location.origin + "/assets/images/document.svg"}
                alt="user-image"
                className="time-line-img"
              />
            </div>
            <p className="time-line-desc">
             {t("time_line_desc")}
            </p>
          </div>
          <div className="howitswork-row">
            <div className="howitswork-icon">
              <Image
                src={window.location.origin + "/assets/images/envelop-pink.svg"}
                alt="user-image"
                className="time-line-img"
              />
            </div>
            <p className="time-line-desc">
             {t("time_line_desc1")}{" "}
              {configuration.get("configData.site_name")}
            </p>
          </div>
          <div className="howitswork-row">
            <div className="howitswork-icon no-after">
              <Image
                src={
                  window.location.origin + "/assets/images/credit-card-pink.svg"
                }
                alt="user-image"
                className="time-line-img"
              />
            </div>
            <p className="time-line-desc">
             {t("time_line_desc2")}
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation(HowItWorksModel);
