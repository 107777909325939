import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import "./Enroll.css";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";

const EnrollSuccess = (props) => {
  return (
    <>
      <div className="main-wrapper">
        <div className="enroll-sec">
          <div className="enroll-card">
            <h3 className="title">{t("your_enrollment_submitted")}</h3>
            <p className="desc">
             {t("enroll_success_note")}
            </p>
            <div className="text-center mt-3">
              <Link to={`/`} className="btn-email btn btn-primary">
               {t("go_home")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(EnrollSuccess));
